import {
  callApiGetMethod,
  callApiPostMethod
} from '../redux/Actions/api.action'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import Cards from '../component/dashboard/cards'
import Footer from '../component/home/footer/footer'
import HeroHeader from '../component/home/hero-header'
import { JoinBanner } from '../component/home/join/join'
import Navigation from './navbar'
import ParallaxSection from '../component/dashboard/parallax-section'
import TotalCard from '../component/dashboard/totalCard'
import UserCustomTable from '../component/dashboard/table'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userAddress = useSelector(state => state?.login?.walletAddress)
  // const referral = useSelector(state => state?.login?.referral)
  const [referralLink, setReferralLink] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [page, setPage] = useState(1)
  const [stakedData, setStakedData] = useState({
    _id: '65f73bd1dd70c79b7d6d60b3',
    walletAddress: userAddress,
    totalBNBStaked: 0,
    isNative: false,
    timestamp: 0,
    eTuktukAmount: 0,
    eHLXAmount: 0,
    totalUSDTStaked: 0,
    totalETHUSDTStaked: 0,
    totalETHStaked: 0,
    bonusInHLX: 0
  })
  const [history, setHistory] = useState([])
  const [explores, setExplores] = useState()
  const [referralBonus, setReferralBonus] = useState()

  const getUserPortfolio = async pageNo => {
    try {
      const res = await dispatch(
        callApiPostMethod(
          'USER_PROFILE',
          { walletAddress: userAddress, page: pageNo },
          '',
          false
        )
      )
      if (!res?.error) {
        setStakedData(res?.data?.data)
        setHistory(res?.data?.transactionHash?.data)
        setExplores(res?.data?.explorer)
        setTotalPages(res?.data?.transactionHash?.totalPages)
      }
    } catch (error) {
      console.log('userError', error)
    }
  }
  // const getUserPortfolio = async () => {
  //   try {
  //     const res = await dispatch(
  //       callApiPostMethod(
  //         'USER_PORTFOLIO',
  //         { walletAddress: userAddress },
  //         '',
  //         false
  //       )
  //     )
  //     if (!res?.error) {
  //       setStakedData(res?.data?.data)
  //       setHistory(res?.data?.transactionHash)
  //       setExplores(res?.data?.explorer)
  //     }
  //   } catch (error) {
  //     console.log('userError', error)
  //   }
  // }
  const getReferralBalance = async () => {
    try {
      const res = await dispatch(
        callApiGetMethod(
          'REFERRAL_BONUS',
          { walletAddress: userAddress },
          '',
          false
        )
      )
      if (!res?.error) {
        setReferralBonus(res?.data?.data)
      }
    } catch (error) {
      console.log('userError', error)
    }
  }
  const getUserReferralLink = async () => {
    try {
      if (userAddress) {
        const res = await dispatch(
          callApiPostMethod(
            'REFERRAL',
            { walletAddress: userAddress },
            '',
            false
          )
        )
        setReferralLink(res.data.data)
      }
    } catch (error) {
      console.log(error, 'error')
    }
  }
  useEffect(() => {
    let timeoutId
    if (userAddress) {
      getReferralBalance()
      getUserReferralLink()
    } else {
      timeoutId = setTimeout(() => {
        navigate('/')
      }, 3000)
    }
    return () => clearTimeout(timeoutId)
  }, [userAddress])
  useEffect(() => {
    if (userAddress) {
      getUserPortfolio(page)
    }
  }, [userAddress, page])

  return (
    <>
      <ParallaxSection>
        <div className="home tw-pb-10">
          <Navigation />
          <div className="container">
            <HeroHeader title="User Dashboard" />
            <Cards {...{ stakedData, referralLink }} />
            <TotalCard {...{ stakedData, referralBonus }} />
            <UserCustomTable {...{ totalPages, setPage }} data={history} />
          </div>
          <JoinBanner />
        </div>
      </ParallaxSection>

      <Footer />
    </>
  )
}

export default Dashboard
