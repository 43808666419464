import { ETH_NETWORKS, NETWORKS } from '../../Services/comon.service'

import binanceIcon from '../../assest/icon.png'
import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    walletAddress: '',
    referralLink: '',
    walletType: '',
    network: [NETWORKS, ETH_NETWORKS],
    bnbPrice: '',
    paymentType: { icon: binanceIcon, value: 'BNB' }
  },
  reducers: {
    setWalletAddress: (state, param) => {
      const { payload } = param
      state.walletAddress = payload
    },
    setLogoutUser: state => {
      state.walletAddress = ''
      state.referralLink = ''
    },
    setNetwork: (state, param) => {
      const { payload } = param
      state.network = payload
    },
    setReferral: (state, param) => {
      const { payload } = param
      state.referralLink = payload
    },

    setBnbPrice: (state, param) => {
      const { payload } = param
      state.bnbPrice = payload
    },
    setPaymentType: (state, param) => {
      const { payload } = param
      state.paymentType = payload
    }
  }
})

export const {
  setNetwork,
  setWalletAddress,
  setBnbPrice,
  setPaymentType,
  setReferral
} = loginSlice.actions

export default loginSlice.reducer
