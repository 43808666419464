import { useEffect, useRef } from 'react'

import FillText from '../../FillText/FillText'
import { Link } from 'react-router-dom'
import ambCrypto from '../../../assest/prLogos/ambCrypto.png'
import analystics from '../../../assest/prLogos/analystics.png'
import beInCrypto from '../../../assest/prLogos/beInCrypto.png'
import bitcoinist from '../../../assest/prLogos/bitcoinist.png'
import coinMarketCap from '../../../assest/prLogos/coinMarketCap.png'
import cryptoDaily from '../../../assest/prLogos/cryptoDaily.png'
import cryptoNews from '../../../assest/prLogos/cryptoNews.png'
import decrypt from '../../../assest/prLogos/decrypt.png'
import financeFeds from '../../../assest/prLogos/financeFeds.png'
import hackernoon from '../../../assest/prLogos/hackernoon.png'
import metaversepost from '../../../assest/prLogos/metaversepost.png'
import newsbtc from '../../../assest/prLogos/newsbtc.png'

export default function FeaturedSlider() {
  const scrollRef = useRef(null)

  const clients = [
    {
      logo: cryptoDaily,
      link: 'https://cryptodaily.co.uk/news-in-crypto/crypto-intelligence:unlocking-new-frontiers-healix-protocol-introduces-hlx-token-presale-alongside-top-crypto-gaming-coins'
    },
    {
      logo: financeFeds,
      link: 'https://financefeeds.com/join-the-revolution-healix-protocol-presale-predicted-to-shake-up-preventive-healthcare-technologies/'
    },
    {
      logo: ambCrypto,
      link: 'https://ambcrypto.com/experience-the-healthcare-revolution-join-healix-protocol-presale-now/'
    },
    {
      logo: beInCrypto,
      link: 'https://beincrypto.com/healix-protocol-presale-gains-traction/'
    },
    {
      logo: metaversepost,
      link: 'https://mpost.io/healix-token-is-leading-the-charge-in-the-600-billion-preventive-healthcare-revolution/'
    },
    {
      logo: newsbtc,
      link: 'https://www.newsbtc.com/press-releases/unlocking-the-power-of-preventive-healthcare-how-healix-protocol-presale-plans-to-revolutionize-the-industry/'
    },
    {
      logo: coinMarketCap,
      link: 'https://coinmarketcap.com/community/articles/66070d3aad15fc1f0e0c87ba/'
    },
    {
      logo: cryptoNews,
      link: 'https://cryptonews.com/news/invest-with-confidence-explore-healix-protocols-hlx-token-presale-now.htm'
    },
    {
      logo: bitcoinist,
      link: 'https://bitcoinist.com/unveiling-the-future-healix-protocols-hlx-token-presale-takes-healthcare-sector-by-storm/'
    },
    {
      logo: decrypt,
      link: 'https://decrypt.co/223208/hlx-token-unveiling-its-presale'
    },
    {
      logo: analystics,
      link: 'https://www.analyticsinsight.net/the-future-is-now-join-healix-protocols-hlx-token-presale-and-ride-the-wave-of-innovation/'
    },
    {
      logo: hackernoon,
      link: 'https://hackernoon.com/healix-presale-revolutionizing-healthcare-with-hlx-tokens'
    }
  ]
  const allClients = [...clients, ...clients]

  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollRef.current) {
        if (scrollRef.current.scrollLeft >= scrollRef.current.scrollWidth / 2) {
          scrollRef.current.scrollLeft = 0
        } else {
          scrollRef.current.scrollLeft += 1
        }
      }
    }, 20) // Adjust the speed by changing the interval time
    return () => clearInterval(interval) // Cleanup interval on component unmount
  }, [])

  return (
    <div className="tw-bg-gradient-to-t tw-from-[#121212] tw-to-[#202020] md:tw-py-14 tw-py-10">
      <h1
        className="tw-font-normal tw-text-5xl"
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1500">
        <FillText className="mx-auto">As seen on</FillText>
      </h1>

      <div
        ref={scrollRef}
        style={{
          overflowX: 'scroll',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none'
        }}
        className="tw-scrollbar-hidden tw-py-14 tw-flex tw-space-x-24 tw-items-center tw-overflow-x-scroll tw-w-full">
        {allClients.map(({ logo, link }, i) => {
          return (
            <img
              key={i}
              alt={logo}
              onClick={() => window.open(link, '_blank')}
              className="tw-h-16 md:tw-h-20 tw-cursor-pointer"
              src={logo}
            />
          )
        })}
      </div>
    </div>
  )
}
