import { createSlice } from "@reduxjs/toolkit";
export const LoaderSlice = createSlice({
  name: "Loader",
  initialState: {
    isLoading: false,
  },

  reducers: {
    setLoading: (state, param) => {
      const { payload } = param;
      state.isLoading = payload;
    },
  },
});

export const { setLoading } = LoaderSlice.actions;
export default LoaderSlice.reducer;
