import React, { useRef } from 'react'
import { m, useScroll, useTransform } from 'framer-motion'

import building from '../../assest/18.png'
import building1 from '../../assest/19.png'

export default function ParallaxSection({ children }) {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end center']
  })
  const section = useRef(null)
  const { scrollYProgress: sectionProgress } = useScroll({
    target: section,
    offset: ['start end', 'end start']
  })
  const building1Y = useTransform(sectionProgress, [0, 1], ['100%', '-100%'])
  return (
    <div
      ref={section}
      className="tw-shadow-2xl tw-relative tw-pt-[60px] tw-pb-[100px] tw-px-5 tw-rounded-[0px_0px_32px_32px] tw-bg-[ tw-#f9f9f9]">
      <m.img
        style={{
          y: building1Y
        }}
        src={building}
        alt="logo"
        className="tw-z-[-1] tw-absolute tw-w-full tw-max-w-[400px] tw-left-0 tw-top-0 tw-hidden md:tw-block"
      />
      <m.img
        src={building1}
        alt="logo"
        className="tw-z-[-1] tw-absolute tw-w-full tw-max-w-[340px] tw-right-0 tw-bottom-[170px] tw-hidden md:tw-block"
        style={{
          y: building1Y
        }}
      />
      <div className="container relative tw-z-10">{children}</div>
    </div>
  )
}
