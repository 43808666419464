import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  theme: "light",
};
export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeTheme: (state, param) => {
      const { payload } = param;
      state.theme = payload;
    },
  },
});

export const { changeTheme } = themeSlice.actions;
