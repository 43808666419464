import Footer from '../component/home/footer/footer'
import HeroHeader from '../component/home/hero-header'
import Join from '../component/home/join/join'
import Navigation from './navbar'
import ParallaxSection from '../component/home/parallax-section'
import React from 'react'

function TermsOfUse() {
  return (
    <>
      <div className="home">
        <Navigation />
        <HeroHeader />
        <ParallaxSection>
          <div className="tw-max-w-4xl tw-mx-auto tw-px-4 tw-py-8">
            <h1 className="tw-text-5xl tw-my-10">Terms of Use</h1>
            <h2 className="tw-text-xl tw-font-bold tw-mb-4">
              1.1 Acceptance of Terms
            </h2>
            <p className="tw-mb-8">
              By accessing the healixprotocol.com website (referred to as the
              "platform"), you are entering into a legally binding agreement
              with Healix Protocol (referred to as the "Company," "Healix LLC,"
              "we," "us," or "our"). These Terms & Conditions govern your use of
              the platform, along with any associated media forms, social media
              channels, or mobile websites. By accessing the platform, you
              acknowledge that you have read, understood, and agreed to comply
              with all these Terms & Conditions. If you do not agree to these
              Terms of Use in their entirety, you are expressly prohibited from
              using the website.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">
              1.2 Legal Authorization
            </h2>
            <p className="tw-mb-8">
              By accessing or using healixprotocol.com, you affirm that you are
              of legal age and have the legal capacity to enter into this
              agreement. If you are accessing or using HealixProtocol.com on
              behalf of an organization, you represent and warrant that you have
              the authority to bind that organization to these Terms.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">
              1.3 Site Interface Disclaimer
            </h2>
            <p className="tw-mb-8">
              HealixProtocol.com facilitates access to a decentralized protocol
              on the Binance Smart Chain Network, allowing users to exchange
              cryptocurrency assets such as ETH, USDT, and BNB for the Healix
              utility token $HLX. Using the platform may entail paying a fee on
              the Healix Protocol platform to execute a transaction. You
              acknowledge and agree that HLX Token has no control over
              transactions, payment methods, or the actual processing of
              transactions. Before initiating any transaction on the platform,
              you must ensure that you have sufficient funds to complete it.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">
              2.1 Compliance with Laws
            </h2>
            <p className="tw-mb-8">
              By using healixprotocol.com, you agree to comply with all
              applicable laws, regulations, and third-party rights.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">
              2.2 Prohibited Activities
            </h2>
            <p className="tw-mb-4">
              You agree not to engage in any illegal, fraudulent, or harmful
              activities using healixprotocol.com, including but not limited to:
            </p>
            <ul className="tw-list-disc tw-ml-4 tw-mb-8">
              <li>
                Violating any applicable laws, regulations, or third-party
                rights.
              </li>
              <li>
                Collecting or compiling data from the platform without
                permission.
              </li>
              <li>
                Deceiving or tricking us or other users to obtain sensitive
                account information.
              </li>
              <li>
                Bypassing or tampering with security features of the platform.
              </li>
              <li>Misusing support services or submitting false reports.</li>
              <li>
                Using the platform for advertising or selling goods and
                services.
              </li>
              <li>Framing or linking to the platform without authorization.</li>
              <li>Uploading viruses or spam that disrupts platform usage.</li>
              <li>Removing copyright notices from content.</li>
              <li>Selling or transferring your profile.</li>
              <li>
                Engaging in cyberattacks or compromising system integrity.
              </li>
              <li>
                Copying or modifying the platform software without permission.
              </li>
              <li>Reverse engineering or disassembling platform software.</li>
              <li>
                Engaging in market manipulation or violating trading market
                regulations.
              </li>
            </ul>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">
              3.1 Availability
            </h2>
            <p className="tw-mb-8">
              While we strive to ensure uninterrupted access to
              healixprotocol.com, we cannot guarantee the continuous or
              error-free operation of our services. We reserve the right to
              temporarily or permanently suspend or terminate healixprotocol.com
              for maintenance, upgrades, security, or other reasons, without
              prior notice.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">
              3.2 Services Disclaimer
            </h2>
            <p className="tw-mb-8">
              HealixProtocol.com provides information and content on its
              platforms. However, it is not intended for distribution or use in
              jurisdictions or countries where such distribution or use would
              violate local laws or regulations or necessitate compliance with
              registration requirements. If you choose to access
              HealixProtocol.com from such locations, you do so voluntarily and
              assume sole responsibility for complying with applicable local
              laws.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">4.1 Ownership</h2>
            <p className="tw-mb-8">
              The intellectual property contained in the materials on the
              Website, as well as any other media form, social media channel, or
              mobile website related, linked, or otherwise connected thereto
              (collectively, the “platform”), are owned by or licensed to
              HealixProtocol.com and are protected by applicable copyright and
              trademark laws. We grant users permission to download one copy of
              the materials for personal, non-commercial transitory use only.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">4.2 License</h2>
            <p className="tw-mb-8">
              Subject to your compliance with these Terms, the use of
              HealixProtocol.com constitutes the grant of a license, not a
              transfer of title. You agree not to use HealixProtocol.com for any
              commercial purposes without our prior written consent. This
              license shall automatically terminate if you violate any of these
              restrictions or the Terms of Service and may be terminated by
              HealixProtocol.com at any time.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">5.1 Volatility</h2>
            <p className="tw-mb-8">
              $HLX is subject to price volatility and market fluctuations. You
              understand and acknowledge the risks associated with investing or
              holding $HLX, and you agree that HealixProtocol.com shall not be
              responsible for any financial losses incurred.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">
              5.2 Third-party Services
            </h2>
            <p className="tw-mb-8">
              The Service may provide links to third-party websites or services
              for your convenience. The Company does not endorse or control
              these third-party services, and you agree that the Company shall
              not be responsible for any content, products, or services provided
              by such third parties. For more information about third-party
              services, please review our Cookies and Privacy Policies.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">
              5.3 User Responsibility
            </h2>
            <p className="tw-mb-8">
              You are solely responsible for the security and custody of your
              $HLX holdings, including but not limited to your private keys,
              passwords, and access codes. You agree to take appropriate
              measures to safeguard your $HLX holdings and protect them from
              unauthorized access or loss.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">
              6.1 Modifications
            </h2>
            <p className="tw-mb-8">
              HealixProtocol.com reserves the right to modify or terminate the
              Service or these Terms at any time, with or without prior notice.
              It is your responsibility to review the Terms periodically for any
              changes. Your continued use of the Service after the modifications
              will constitute your acceptance of the revised Terms.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">6.2 Termination</h2>
            <p className="tw-mb-8">
              HealixProtocol.com may suspend or terminate your access to the
              Service at any time, with or without cause, in its sole
              discretion. Upon termination, all licenses and rights granted to
              you under these Terms will immediately cease.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">Severability</h2>
            <p className="tw-mb-8">
              Any term found in these Terms of Service that is wholly or
              partially void or unenforceable is severed to the extent that it
              is void or unenforceable. The validity of the remainder of these
              Terms of Service is not affected.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">
              Entire Agreement
            </h2>
            <p className="tw-mb-8">
              These Terms constitute the entire agreement between you and
              HealixProtocol.com regarding your use of HealixProtocol.com and
              supersede any prior agreements or understandings, whether oral or
              written.
            </p>

            <h2 className="tw-text-xl tw-font-bold tw-mb-4">Contact Us</h2>
            <p>
              Questions, concerns, and inquiries regarding these Terms of
              Service can be directed to HealixProtocol.com through this contact
              email:{' '}
              <a
                href="mailto:support@healixprotocol.com"
                className="tw-text-blue-500">
                support@healixprotocol.com
              </a>
              . By using HealixProtocol.com, you agree to abide by these Terms
              of Service.
            </p>
          </div>
        </ParallaxSection>
        <Join />
      </div>
      <Footer />
    </>
  )
}

export default TermsOfUse
