import './footer.css'

import React from 'react'
import footerlogo from '../../../assest/footerlogo.png'
import { m } from 'framer-motion'
import send from '../../../assest/send.png'

export default function Footer() {
  const menuList = [
    {
      name: 'Whitepaper',
      url: 'https://healix-protocol.gitbook.io/healix-whitepaper'
    },
    {
      name: 'Privacy Policy',
      url: 'privacy-policy'
    },
    {
      name: 'Terms of Use',
      url: '/terms-of-use'
    }
  ]

  return (
    <div className="footer">
      <div className="footer-back ">
        <m.h2
          initial={{ y: 30, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}>
          Be a part of the Healix Journey!!
        </m.h2>
        <div className="form-footer">
          <input placeholder="Enter your email address." />
          <img src={send} alt="send" />
        </div>
        <div className="tw-flex md:tw-flex-row tw-flex-col tw-items-baseline tw-justify-between tw-flex-wrap tw-mt-24">
          <img src={footerlogo} alt="logo" />
          <div className="tw-flex tw-mt-5 tw-gap-3 tw-items-center  tw-justify-center md:tw-justify-end md:tw-w-fit tw-w-full">
            {/* <li>blog </li> */}
            {/* <li> careers</li> */}
            {/* <li> news</li> */}
            {menuList.map((menuList, index) => (
              <div key={index}>
                <a
                  className="tw-no-underline tw-text-black"
                  href={menuList.url}
                  // target="_blank"
                  rel="noopener noreferrer">
                  {menuList.name}
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="tw-mt-10 md:tw-mt-14 tw-opacity-80 tw-text-sm">
          <p className="tw-font-bold tw-m-0">*Disclaimer</p>
          <p className="tw-mt-2">
            Digital currencies may be unregulated in your jurisdiction. The
            value of digital currencies may go down as well as up. Profits may
            be subject to capital gains or other taxes applicable in your
            jurisdiction.
          </p>
        </div>
      </div>
    </div>
  )
}
