import './banner.css'

import React from 'react'
import { m } from 'framer-motion'

function HeroHeader({
  desc = 'Global decentralized healthcare ecosystem to make healthcare more affordable and accessible to all, using the power of data science and AI.',
  title = ''
}) {
  const variants = {
    initial: {
      x: -100,
      opacity: 0
    },
    open: {
      x: 0,
      opacity: 1
    }
  }
  const logo = [
    'M98.5581 1.58911V109.198H83.0163V59.3563H19.7684V109.198H4.22656V1.58911H19.7684V43.8145H83.0163V1.58911H98.5581Z',
    'M211.263 1.38306V16.9249H115.902V1.38306H211.263ZM131.444 93.6561H211.237V109.198H115.876V1.38306H131.418C131.418 15.4582 131.418 29.5333 131.418 43.6084H200.764V59.1759H131.418V93.6561H131.444Z',
    'M271.473 28.7618L281.689 47.3914L247.492 109.198H226.958L271.473 28.7618ZM346.738 109.198H326.179L286.835 38.1023L276.568 19.5499L286.835 0.997559L297.102 19.5499L346.738 109.198Z',
    'M446.165 93.6564V109.198H362.255V0.53418H377.797V93.6564H446.139H446.165Z',
    'M461.604 109.198V1.15161H477.145V109.198H461.604ZM461.604 1.15161V109.198H477.145V1.15161H461.604ZM476.631 1.66624H462.118V108.683H476.631V1.66624Z',
    'M543.378 40.2378C546.594 44.6121 549.811 48.9865 553.027 53.3608C539.312 71.9647 525.623 90.5942 511.908 109.198H492.584C509.515 86.2199 526.447 63.216 543.352 40.2378H543.378ZM594.172 109.198H574.847L543.378 66.4839L533.703 53.3865L532.288 51.4567L495.389 1.33179H514.687L541.937 38.3336L543.352 40.2378L544.767 38.3336L572.017 1.33179H591.341L554.442 51.4567L553.027 53.3608L594.146 109.198H594.172Z'
  ]
  return (
    <div className="container-box">
      <div className="box box1">
        {/* <img src={logo2} alt="logo" /> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="599"
          height="118"
          viewBox="0 0 599 118"
          fill="none">
          <g filter="url(#filter0_d_617_3600)">
            {logo.map((item, index) => {
              return (
                <m.path
                  initial="initial"
                  whileInView={'open'}
                  variants={document?.body?.clientWidth > 991 ? variants : ''}
                  d={item}
                  key={index}
                  fill="black"
                  transition={{
                    delay: index * 0.0
                  }}
                />
              )
            })}
          </g>
          <defs>
            <filter
              id="filter0_d_617_3600"
              x="0.226562"
              y="0.53418"
              width="597.945"
              height="116.664"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_617_3600"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_617_3600"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="box box2">
        {{ title } ? (
          <div className="">
            <m.h3
              initial={{
                y: 40
              }}
              whileInView={{
                y: 0
              }}
              className="typing-demo tw-font-normal tw-text-4xl tw-text-right">
              {title}
            </m.h3>
          </div>
        ) : (
          <m.p
            initial={{
              y: 40
            }}
            whileInView={{
              y: 0
            }}
            className="typing-demo">
            {desc}
          </m.p>
        )}
        {/* <m.p
          initial={{
            y: 40
          }}
          whileInView={{
            y: 0
          }}
          className="typing-demo">
          {desc}
        </m.p> */}
      </div>
    </div>
  )
}

export default HeroHeader
