import React, { useRef } from 'react'
import "./FillText.css";
import { useScroll, m, useTransform } from 'framer-motion';

const FillText = ({ children, color, className }) => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start end", "end center"],
    });
    const clipPath = useTransform(scrollYProgress, [0, 1], ["polygon(0 0, 0% 0, 0% 100%, 0% 100%)", "polygon(0 0, 100% 0, 100% 100%, 0% 100%)"])
    return (
        <div ref={ref} className={`fill_text ${color === "black" ? "fill_black" : ""} ${className || ""}`}>
            {children}
            <m.div style={{ clipPath }}>
                {children}
            </m.div>
        </div>
    )
}

export default FillText

