import './token.css'

import React, { useRef } from 'react'
import { m, useScroll, useTransform } from 'framer-motion'

import FillText from '../../FillText/FillText'
import Image from '../../Image/Image'
import arrowbutton from '../../../assest/arrowbutton.png'
import building from '../../../assest/18.png'
import building1 from '../../../assest/19.png'
import logo from '../../../assest/logo.png'
import payment from '../../../assest/payment.png'
import reward from '../../../assest/reward.png'
import staking from '../../../assest/staking.png'
import token1 from '../../../assest/token1.png'
import tokenomics from '../../../assest/tokenomics.png'
import tokenomics2 from '../../../assest/tokenomics2.png'
import tokenomics3 from '../../../assest/tokenomicsUpdated.png'

export default function Token() {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end center']
  })
  const clipPath = useTransform(
    scrollYProgress,
    [0, 1],
    [
      'polygon(0 0, 0% 0, 0% 100%, 0% 100%)',
      'polygon(0 0, 100% 0, 100% 100%, 0% 100%)'
    ]
  )

  const section = useRef(null)
  const { scrollYProgress: sectionProgress } = useScroll({
    target: section,
    offset: ['start end', 'end start']
  })
  const building1Y = useTransform(sectionProgress, [0, 1], ['100%', '-100%'])

  const tokenomicsRef = useRef(null)
  const { scrollYProgress: tokenomicsProgress } = useScroll({
    target: tokenomicsRef,
    offset: ['start end', 'end start']
  })
  const loyilty = useRef(null)
  const { scrollYProgress: loyiltyProgress } = useScroll({
    target: loyilty,
    offset: ['start end', 'end start']
  })

  return (
    <div ref={section} className="section-token">
      <m.img
        style={{
          y: building1Y
        }}
        src={building}
        alt="logo"
        className="building"
      />
      <m.img
        src={building1}
        alt="logo"
        className="building1"
        style={{
          y: building1Y
        }}
      />
      <div className="container">
        <div
          className="flex buy-hlx"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          data-aos-duration="1500">
          <img src={logo} alt="logo" />{' '}
          <h1 ref={ref}>
            The HLX Token
            <m.div style={{ clipPath }}>The HLX Token</m.div>
          </h1>
        </div>
        <div ref={tokenomicsRef} className="tokenomics">
          <m.img
            style={{
              y: useTransform(tokenomicsProgress, [0, 1], [50, -50])
            }}
            src={tokenomics3}
            alt="token"
            data-aos="fade-left"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500"
          />
        </div>
        <div
          // style={{ border: '1px solid black' }}
          className="tw-flex tw-flex tw-justify-center tw-items-center tw-mx-0 tw-my-5 tw-rounded-[1rem] md:tw-rounded-[2rem] tw-h-fit">
          <h4 className="md:tw-p-6 tw-p-3 tw-my-0 md:tw-text-5xl tw-text-3xl tw-font-normal">
            {/* Utilities */}
            <FillText color="black">Utilities</FillText>
          </h4>
        </div>
        <div ref={loyilty} className="token-1">
          <m.img
            style={{
              y: useTransform(loyiltyProgress, [0, 1], [30, -30])
            }}
            src={token1}
            alt="token"
            data-aos="fade-left"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500"
          />
          <div
            className="token-1-body"
            data-aos="fade-right"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500">
            <p>
              The HLX token serves as a loyalty token within the ecosystem,
              allowing users to access exclusive discounts, offers, and rewards
              from partner institutions.
            </p>
            <span></span>
            <h2>
              <FillText>Loyalty</FillText>
            </h2>
          </div>
        </div>

        <div className="reward">
          <div
            className="reward-body"
            data-aos="fade-left"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500">
            <h2>
              <FillText color="black">Rewards</FillText>
            </h2>
            <p>
              HLX token rewards users for contributing to the network, achieving
              health goals, and engaging with the platform's services and
              programs. Users earn rewards in the form of additional HLX tokens,
              incentivizing healthy behaviors and participation.
            </p>
            <Image
              src={payment}
              alt="reward"
              data-aos="fade-left"
              data-aos-anchor-placement="top-center"
              data-aos-duration="1500"
            />
          </div>
          <div
            className="reward-body-2"
            data-aos="fade-right"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500">
            <Image src={reward} alt="payment" />
            <h2>
              <FillText color="black">Payment</FillText>
            </h2>
            <p>
              HLX token facilitates seamless transactions within the ecosystem,
              enabling users to make payments for healthcare services and
              wellness products.
            </p>
          </div>
        </div>

        {/* <div className="token-1" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1500">
          <Image src={staking} alt="staking" />
          <div className="token-1-body">

            <h2>
              <FillText>
                Staking
              </FillText>
            </h2>
            <span></span>
            <p>
              Staking HLX tokens strengthens the network and rewards participants. Users can participate in the Smart Health Program by locking tokens, earning additional rewards while supporting the ecosystem's growth.
            </p>

          </div>
          <button className="staking">Whitepaper</button>
        </div> */}
        <m.img
          initial={{
            y: '20%'
          }}
          animate={{
            y: '0%'
          }}
          transition={{
            type: 'bounce',
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 3
          }}
          src={arrowbutton}
          alt="arrowbutton"
          className="arrowbutton my_animation"
        />
      </div>
    </div>
  )
}
