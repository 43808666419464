import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'

import { apiCallPost, apiCallGet } from '../../Services/axios.service'

import { setLoading } from '../features/loading.slice'
import { APIURL } from '../../Services/comon.service'

/**CALL API'S GET METHODS */
export const callApiGetMethod = (
  method,
  parms = {},
  loading = true,
  showtoaster = true
) => {
  return (dispatch = useDispatch(), getState) =>
    new Promise(async (resolve, reject) => {
      /**SHOW LOADER */
      if (loading) dispatch(setLoading(true))

      /**CALL METHOD */
      apiCallGet(APIURL[method], parms, showtoaster)
        .then(result => {
          if (loading) dispatch(setLoading(false))
          resolve(result)
        })
        .catch(err => {
          console.log(err, 'err')
          if (loading) dispatch(setLoading(false))
          reject(err)
        })
    })
}

/**CALL API'S SEND METHOD */
export const callApiPostMethod = (
  method,
  data = {},
  parms = {},
  showtoaster = true
) => {
  return (dispatch = useDispatch(), getState) =>
    new Promise(async (resolve, reject) => {
      /**SHOW LOADER */
      dispatch(setLoading(true))

      /**CALL METHOD */
      apiCallPost(APIURL[method], data, parms, showtoaster)
        .then(result => {
          dispatch(setLoading(false))
          resolve(result)
        })
        .catch(err => {
          console.log(err, 'err')

          dispatch(setLoading(false))
          reject(err)
        })
    })
}

export const callApiPostNew = (
  method,
  data = {},
  parms = {},
  showtoaster = true
) => {
  return (dispatch = useDispatch(), getState) =>
    new Promise(async (resolve, reject) =>
      apiCallPost(APIURL[method], data, parms, showtoaster)
        .then(result => resolve(result))
        .catch(err => reject(err))
    )
}
