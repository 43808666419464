import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assest/logo.png";
import { m } from "framer-motion";
import { lenis } from "../App";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  API_HOST,
  addresFormate,
  handleWhitePaper,
} from "../Services/comon.service";
import { useUserWalletConnect } from "../Services/useWalletConnect";
import { getOwner } from "../Services/Contract.service";
import axios from "axios";
import toaster from "../component/Toast";
import { setLoading } from "../redux/features/loading.slice";
const Navigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard";
  const userDetails = useSelector((state) => state?.login);
  const { walletProvider, open } = useUserWalletConnect();

  const [isOpen, setIsOpen] = useState(false);
  const [healixOwner, setHealixOwner] = useState();

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getEtukOwner = async () => {
    try {
      const data = await getOwner(walletProvider);
      setHealixOwner(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const goToDashboard = () => {
    navigate("/dashboard");
    window.location.reload();

  };
  const goToHome = () => {
    navigate("/");
    window.location.reload();
  };

  const downloadCsv = async () => {
    const apiUrl = `${API_HOST}user/export-user`;
    const payload = {
      walletAddress: userDetails.walletAddress?.toLowerCase(),
    };

    try {
      const response = await axios.post(apiUrl, payload, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers if needed
        },
      });
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");
      const hours = currentDate.getHours().toString().padStart(2, "0");
      const minutes = currentDate.getMinutes().toString().padStart(2, "0");

      const formattedDateTime = `${day}-${month}-${year} ${hours}-${minutes}`;
      const blob = response.data;
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `usersReport${formattedDateTime}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toaster.success("Download successful");
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getEtukOwner();
    }, 3000)
  }, [userDetails.walletAddress]);

  useEffect(() => {
    if (isOpen) {
      lenis.stop();
    } else {
      lenis.start();
    }
  }, [isOpen]);

  useEffect(() => {
    dispatch(setLoading(false));
  }, []);
  return (
    <div className="container">
      <nav>
        <div className="header_logo">
          <m.img
            initial={{
              rotateY: 0,
            }}
            whileInView={{
              rotateY: 180,
            }}
            transition={{
              duration: 1,
              type: "spring",
              repeat: Infinity,
              repeatType: "reverse",
            }}
            src={logo}
            className="App-logo"
            alt="logo"
            onClick={() => {
              goToHome();
            }}
          />
        </div>
        <ul>

          {isDashboard && (
            <m.li
              initial={{ y: -30, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              onClick={() => goToHome()}
            >
              <Link
                to="#"
                className="buy_btn mx-4"
                onClick={() => goToHome()}
              >
                Home
              </Link>
            </m.li>
          )}
          <m.li
            initial={{ y: -30, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.1 }}
            onClick={() => handleWhitePaper()}
          >
            <Link to="/">
              <span data-text="WhitePaper">Whitepaper</span>
            </Link>
          </m.li>
          <div className="dashboard">
            {healixOwner?.toLowerCase() ==
              userDetails.walletAddress?.toLowerCase() ? (
              <>
                {userDetails.walletAddress && !isDashboard && (
                  <m.li
                    initial={{ y: -30, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    onClick={() => downloadCsv()}
                  >
                    <Link to="#" onClick={() => downloadCsv()}>
                      <span data-text="Download"> Download</span>
                    </Link>
                  </m.li>
                )}
              </>
            ) : (
              userDetails.walletAddress &&
              !isDashboard && (
                <m.li
                  // initial={{ y: -30, opacity: 0 }}
                  // whileInView={{ y: 0, opacity: 1 }}
                  // transition={{ delay: 0.2 }}
                  onClick={() => goToDashboard()}
                >
                  <Link
                    // to="#"
                    className="buy_btn mx-4"
                    onClick={() => goToDashboard()}
                  >
                    Dashboard
                  </Link>
                </m.li>

              )
            )}

          </div>
          {userDetails?.walletAddress ? (
            <m.li
              initial={{ y: -30, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              onClick={() => open()}
            >
              <Link to="#">
                <span data-text="Connect">
                  {addresFormate(userDetails?.walletAddress)}
                </span>
              </Link>
            </m.li>
          ) : (
            <m.li
              initial={{ y: -30, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              onClick={() => open()}
            >
              <Link to="#">
                <span data-text="Connect">Connect</span>
              </Link>
            </m.li>
          )}

        </ul>
      </nav>
      {/* <AnimatePresence>
        {isOpen && (
          <m.div
            className="modal"
            initial={{
              opacity: 0,
              scale: 0,
            }}
            animate={{
              opacity: 1,
              scale: 1,
            }}
            exit={{
              opacity: 0,
              scale: 0,
            }}
          >
            <div className="modal_content">
              <h6>Connect Wallet</h6>
              <button>
                <img src={dog} alt="icon" /> Metamask
              </button>
              <button>
                <img src={dog1} alt="icon" /> WalletConnect
              </button>
            </div>
            <div className="modal_overlay" onClick={() => setIsOpen(false)} />
          </m.div>
        )}
      </AnimatePresence> */}
    </div>
  );
};

export default Navigation;
