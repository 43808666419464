import { addresFormate, formatTimestamp } from '../../Services/comon.service'

import hlx_logo from '../../assest/hlx-logo-black.png'
import pageArrow from '../../assest/paginationArrow.svg'

export default function UserCustomTable({
  totalPages,
  page = 0,
  data = [],
  sort,
  reFetch,
  setSort = () => {},
  setPage = () => {},
  isAuthenticate = false,
  searchData
}) {
  const TableHeader = [
    { id: 'txn-no', title: 'TXN No', logo: '', isSort: false },
    {
      id: 'amount',
      title: 'Amount',
      logo: ''
    },

    {
      id: 'bonusInHlx',
      title: 'HLX',
      logo: hlx_logo
    },
    {
      id: 'dateTime',
      title: 'Date/Time',
      logo: ''
    }
  ]
  return (
    <div
      style={{ border: '1px solid black' }}
      className="tw-bg-white tw-rounded-3xl tw-w-full tw-p-5 md:tw-p-10 tw-rounded-3xl">
      <h3 className="tw-text-4xl !tw-font-normal">Recent Transactions</h3>
      <hr />
      <div className="tw-flex tw-gap-5 tw-mt-10 tw-hide-scrollbar tw-overflow-scroll scrollbar-hide">
        <div className="tw-grid tw-w-full">
          <div className="tw-flex tw-w-full">
            <div className="tw-w-full tw-grid tw-grid-cols-4 tw-px-3 tw-py-3  tw-my-3 tw-min-w-[700px]">
              {TableHeader.map(({ id, isSort, title, logo, keyValue }) => {
                return (
                  <TableColumn key={title}>
                    {logo && (
                      <img
                        alt={title}
                        className="tw-hidden md:tw-block"
                        width={25}
                        height={25}
                        src={logo}
                      />
                    )}
                    {title}
                  </TableColumn>
                )
              })}
            </div>
          </div>
          <div className="tw-space-y-2">
            {data?.length === 0 ? (
              <div
                style={{ border: '1px solid #303241' }}
                className="tw-w-full tw-grid tw-min-w-[700px] tw-rounded-md tw-hover:bg-black/70 tw-text-white  tw-text-sm tw-bg-[#232323] tw-my-2">
                <p className="tw-text-center tw-opacity-70 !tw-text-xl">
                  No Transactions
                </p>
              </div>
            ) : (
              data.map((item, i) => {
                return (
                  <div
                    key={`tableRow${i}`}
                    style={{ border: '1px solid #303241' }}
                    className="tw-w-full tw-grid tw-grid-cols-4 tw-min-w-[700px] tw-rounded-md tw-hover:bg-black/70 tw-text-white  tw-text-sm tw-bg-[#232323] tw-my-2">
                    <div className="tw-min-w-[80px] tw-text-center tw-text-blue-600">
                      <p>
                        <span className="tw-text-white tw-mr-1">{i + 1}</span>
                        <a
                          className="tw-text-blue-400 tw-no-underline"
                          // href={`${explores + item.transactionHash}`}
                          href={`https://bscscan.com/tx/${item.transactionHash}`}
                          // href={`https://testnet.bscscan.com/tx/${item.transactionHash}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          {addresFormate(item.transactionHash)}
                        </a>
                      </p>
                    </div>
                    <div className="tw-min-w-[80px] tw-overflow-scroll tw-text-center scrollbar-hide">
                      <p>
                        {item.amount}{' '}
                        {item?.currency
                          ? item?.currency
                          : item?.isNative
                          ? 'BNB'
                          : 'BUSD'}
                      </p>
                    </div>
                    <div className="tw-min-w-[80px] tw-text-center">
                      <p>
                        {item?.eHLXAmount?.toFixed(4) ||
                          item?.eTuktukAmount?.toFixed(4)}{' '}
                        HLX
                      </p>
                    </div>

                    <div className="tw-min-w-[80px] tw-text-center tw-flex tw-flex-col tw-items-center tw-justify-center">
                      <p className="!tw-mb-0 !tw-mt-1">
                        {formatTimestamp(item?.timestamp).date}
                      </p>
                      <p className="!tw-mt-0 !tw-mb-1 !tw-text-sm tw-opacity-60">
                        {formatTimestamp(item?.timestamp).time}
                      </p>
                    </div>
                  </div>
                )
              })
            )}
          </div>
        </div>
      </div>
      {data?.length ? <Pagination {...{ page, setPage, totalPages }} /> : null}
    </div>
  )
}
function TableColumn({ children }) {
  return (
    <div className="tw-min-w-[80px] tw-text-black tw-text-sm tw-text-center tw-flex tw-justify-center tw-items-center tw-gap-2">
      {children}
    </div>
  )
}
function Pagination({ totalPages = 1, page = 1, setPage = () => {} }) {
  const handlePrevious = () => {
    if (page === 0) return setPage(page)
    return setPage(page => page - 1)
  }

  const handleNext = () => {
    if (page === totalPages - 1) return setPage(page)
    return setPage(page => page + 1)
  }

  const renderPageNumbers = () => {
    const maxVisiblePages = 5
    let startPage = Math.max(0, page - Math.floor(maxVisiblePages / 2))
    let endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 1)

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(0, endPage - maxVisiblePages + 1)
    }

    const pages = []
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <div
          role="button"
          onClick={() => setPage(i + 1)}
          key={`page${i}`}
          className={`tw-flex tw-items-center tw-justify-center tw-p-3 tw-text-sm tw-w-5 tw-h-5 tw-text-center tw-hover:bg-[#2B79D3] tw-text-gray-300 tw-hover:text-white tw-rounded-full tw-cursor-pointer ${
            page === i ? 'tw-bg-[#2B79D3]' : ''
          }`}>
          {i + 1}
        </div>
      )
    }
    return pages
  }

  return (
    <div className="tw-w-full tw-my-12">
      <div className="tw-flex tw-gap-5 tw-justify-center tw-items-center">
        <img
          onClick={handlePrevious}
          className="tw-cursor-pointer tw-rotate-180"
          src={pageArrow}
          alt="<"
        />
        <div className="tw-flex tw-gap-1">{renderPageNumbers()}</div>
        <img
          onClick={handleNext}
          className="tw-cursor-pointer"
          src={pageArrow}
          alt=">"
        />
      </div>
    </div>
  )
}
