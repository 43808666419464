import React from 'react'
import { m } from "framer-motion";

const Image = ({ src, alt }) => {
    return (
        <>
            <m.img
                src={src}
                alt={alt}
                initial={{
                    scale: document.body.clientWidth > 991 ? 1.5 : 1,
                    filter: 'blur(10px)'
                }}
                whileInView={{
                    scale: 1,
                    filter: "blur(0px)"
                }}
                transition={{
                    delay: 0.1,
                    duration: 0.4,
                    type: "tween"
                }}
            />
        </>
    )
}

export default Image
