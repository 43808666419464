import React, { useState } from 'react'

import CopyToClipboard from 'react-copy-to-clipboard'
import CountUp from 'react-countup'
import bnb_logo from '../../assest/bnb-logo-black.png'
import busd_logo from '../../assest/busd-logo-black.png'
import eth_logo from '../../assest/eth-logo-black.png'
import globe from '../../assest/globe-bg.jpg'
import usdt_logo from '../../assest/usdt-logo-black.png'

function Cards({ stakedData, referralLink }) {
  const [isCopied, setIsCopied] = useState(false)

  const deposits = [
    {
      item: 'BNB',
      logo: bnb_logo,
      value: stakedData?.totalBNBStaked.toFixed(4)
    },
    {
      item: 'ETH',
      logo: eth_logo,
      value: stakedData?.totalETHStaked.toFixed(4)
    },
    {
      item: 'USDT',
      logo: usdt_logo,
      value: stakedData?.totalETHUSDTStaked.toFixed(4)
    },
    {
      item: ' USDT(BEP20)',
      logo: busd_logo,
      value: stakedData?.totalUSDTStaked.toFixed(4)
    }
  ]
  return (
    <div className="tw-mt-10 tw-flex md:tw-flex-row tw-flex-col tw-gap-5">
      <div
        style={{ border: '1px solid black' }}
        className="md:tw-basis-1/2 tw-p-5 tw-border tw-border-black tw-rounded-3xl tw-divide-y tw-space-y-5 tw-py-10 tw-bg-white">
        <h2 className="tw-font-normal tw-text-3xl tw-mt-0">My Deposits</h2>
        <hr />
        <div className="tw-space-y-5">
          <div className="tw-flex gap-10">
            <p className="tw-basis-1/2 tw-pl-14">Token</p>
            <p className="tw-basis-1/2 tw-text-center">Amount</p>
          </div>
          {deposits.map(({ item, logo, value }, i) => {
            return (
              <div
                key={i}
                className="tw-bg-[#EFEFEF] tw-px-4 tw-rounded-md tw-flex tw-justify-between">
                <div className="tw-basis-1/2 tw-flex tw-items-center tw-gap-3">
                  <img alt={item} src={logo} width={37} height={38} />
                  <p>{item}</p>
                </div>
                <div className="tw-basis-1/2 tw-flex tw-gap-3 tw-justify-center">
                  <p>{value ? value : 0}</p>
                  {/* <p>{<CounterText end={value} decimals={4} />}</p> */}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${globe})`
        }}
        className="md:tw-basis-1/2 tw-p-10 tw-border tw-border-black tw-rounded-3xl tw-bg-[#E3E3E3] tw-relative tw-bg-cover tw-bg-no-repeat tw-flex tw-flex-col tw-justify-center">
        <h2 className="tw-text-7xl tw-my-0">
          <span className="tw-text-[#4D4D4D]">Earn a</span> 5% bonus
        </h2>
        <h4 className="tw-text-4xl tw-font-medium">from all referred users!</h4>
        <div
          style={{ border: '1px solid #4D4D4D' }}
          className="tw-flex tw-rounded-md tw-overflow-clip">
          <input
            value={referralLink}
            readOnly
            style={{ border: 'none' }}
            className="tw-h-12 tw-w-full tw-p-5"
            placeholder="Connect Wallet for Referral Link"
          />
          <CopyToClipboard onCopy={() => setIsCopied(true)} text={referralLink}>
            {referralLink ? (
              <button
                style={{ border: 'none' }}
                className="tw-cursor-pointer tw-bg-[#4D4D4D] tw-text-white tw-w-20 tw-h-12 hover:tw-text-white/70">
                {isCopied ? 'Copied' : 'Copy'}
              </button>
            ) : (
              <button
                disabled
                style={{ border: 'none' }}
                className="tw-cursor-pointer tw-bg-[#4D4D4D] tw-text-white tw-w-20 tw-h-12 hover:tw-text-white/70">
                {isCopied ? 'Copied' : 'Copy'}
              </button>
            )}
          </CopyToClipboard>
        </div>
      </div>
    </div>
  )
}

export default Cards
export const CounterText = ({ start, end, decimals }) => {
  return (
    <>
      <CountUp
        start={start}
        end={end}
        duration={2}
        enableScrollSpy
        separator=" "
        decimals={decimals}
        // decimal="."
        style={{ fontVariantNumeric: 'tabular-nums' }}
        scrollSpyOnce
      />
    </>
  )
}
