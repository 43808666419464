import { configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import { rootReducer } from './reducer'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  version: 2,
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export default store
