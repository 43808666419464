import './App.css'

import {
  ETH_NETWORKS,
  NETWORKS,
  metadata,
  projectId
} from './Services/comon.service'
import { LazyMotion, domMax } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

import Cursor from './component/cursor/cursor'
import Dashboard from './page/dashboard'
import Home from './page/home'
import Lenis from '@studio-freight/lenis'
import Loader from './component/Loader'
import PrivacyPolicy from './page/privacy-policy'
import { Provider } from 'react-redux'
import ReferralPage from './page/referral-page'
import TermsOfUse from './page/terms-of-use'
import { Toaster } from 'react-hot-toast'
import store from './redux/store/store'

export const lenis = new Lenis({
  lerp: 0.08
})
const bnbChain = {
  chainId: NETWORKS.chainId,
  name: NETWORKS.name,
  currency: NETWORKS.currency,
  explorerUrl: NETWORKS.explorerUrl,
  rpcUrl: NETWORKS.rpcUrl
}

const ethChain = {
  chainId: ETH_NETWORKS.chainId,
  name: ETH_NETWORKS.name,
  currency: ETH_NETWORKS.currency,
  explorerUrl: ETH_NETWORKS.explorerUrl,
  rpcUrl: ETH_NETWORKS.rpcUrl
}

createWeb3Modal({
  featuredWalletIds: [
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709'
  ],
  includeWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
    'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'
  ],
  ethersConfig: defaultConfig({
    metadata,
    defaultChainId: Number(NETWORKS.chainId),
    enableEIP6963: true,
    enableInjected: false,
    enableCoinbase: false,
    rpcUrl: '...'
  }),
  chains: [bnbChain, ethChain],
  projectId
})
const App = () => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    function raf(time) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)
  }, [])

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        // allowing 3 seconds to fade out loader
        var loader = document.querySelector('.page-loader')
        if (loader) {
          loader.style.transition = 'slow'
          loader.style.opacity = '0'
          setTimeout(function () {
            loader.style.display = 'none'
            setShow(true)
          }, 350)
        }
      }, 1500)
    }
  }, [])

  // useEffect(() => {
  //   const cursor = document.querySelector('.cursor');

  //   document.addEventListener('mousemove', e => {
  //     cursor.setAttribute("style", "top: " + (e.pageY - 10) + "px; left: " + (e.pageX - 10) + "px;")
  //   });

  //   document.addEventListener('click', e => {
  //     cursor.classList.add("expand");
  //     setTimeout(() => {
  //       cursor.classList.remove("expand");
  //     }, 500);
  //   });
  // }, [])
  // useEffect(() => {
  //     AOS.init();
  //     AOS.refresh();
  // }, []);
  return (
    <>
      <Provider store={store}>
        <LazyMotion strict features={domMax}>
          {!show && (
            <div className="page-loader">
              <div className="spinner"></div>
              <div className="txt">HEALIX</div>
            </div>
          )}
          {/* <div className="cursor"></div> */}
          {document.body.clientWidth > 767 && <Cursor />}
          {show && (
            <Router>
              <Routes>
                <Route path="/" element={<Home />} />
              </Routes>
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
              </Routes>
              <Routes>
                <Route path="/terms-of-use" element={<TermsOfUse />} />
              </Routes>
              <Routes>
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              </Routes>
              <Routes>
                <Route path="/r/:referral" element={<ReferralPage />} />
              </Routes>
            </Router>
          )}
          <Loader />
        </LazyMotion>
        <Toaster />
      </Provider>
    </>
  )
}

export default App
