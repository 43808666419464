import './crypto.css'

import React, { useRef } from 'react'
import { m, useScroll, useTransform } from 'framer-motion'

import FillText from '../../FillText/FillText'
import block from '../../../assest/block.png'
import buyHlx from '../../../assest/buyHlx.png'
import coin_one from '../../../assest/coin5.png'
import coin_two from '../../../assest/coin2.png'
import group from '../../../assest/Group(5).png'
import icon5 from '../../../assest/icon5.png'
import icon6 from '../../../assest/icon6.png'
import icon7 from '../../../assest/icon7.png'
import logo from '../../../assest/logo.png'

export default function Crypto() {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  })
  const hlxRef = useRef(null)
  const { scrollYProgress: hlxProgress } = useScroll({
    target: hlxRef,
    offset: ['start end', 'end start']
  })
  const y = useTransform(scrollYProgress, [0, 1], [500, -500])
  return (
    <div ref={ref} className="crypto">
      <m.img
        style={{ y }}
        src={group}
        alt="logo"
        className="tw-hidden md:tw-block crypto_icon"
      />
      <m.img
        style={{ y }}
        src={block}
        alt="logo"
        className="tw-hidden md:tw-block block"
      />
      <m.img
        style={{ y }}
        src={coin_one}
        alt="logo"
        className="tw-hidden md:tw-block coin_one"
      />
      <m.img
        style={{ y }}
        src={coin_two}
        alt="logo"
        className="tw-hidden md:tw-block coin_two"
      />
      <div className="container">
        <div
          className="flex buy-hlx"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          data-aos-duration="1500">
          <img src={logo} alt="logo" />{' '}
          <h1
            data-aos="fade-left"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500">
            <FillText color="black" className={''}>
              How to buy HLX
            </FillText>
          </h1>
        </div>
        <div ref={hlxRef} className="buyHlx">
          <m.img
            style={{
              y: useTransform(hlxProgress, [0, 1], [50, -50])
            }}
            src={buyHlx}
            alt="token"
            data-aos="fade-left"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500"
          />
        </div>
        <h2
          data-aos="fade-left"
          data-aos-anchor-placement="top-center"
          data-aos-duration="1500">
          <FillText color="black" className={'ms-auto'}>
            Buy with crypto
          </FillText>
        </h2>
        <div
          className="crypto-back"
          data-aos="fade-left"
          data-aos-anchor-placement="top-center"
          data-aos-duration="1500">
          <div className="step">
            <FillText color="black">Step1</FillText>
          </div>
          <div className="body">
            <div className="mask">
              <img src={icon5} alt="logo" />
              <m.h1
                initial={{ y: 30, opacity: 0 }}
                transition={{ delay: 0.1 }}
                whileInView={{ y: 0, opacity: 1 }}>
                Install Metamask/Trust Wallet
              </m.h1>
            </div>
            <p>
              Firstly, make sure you have one of the supported wallets ready.
              For desktop, we recommend using the Metamask browser extension.
              For mobile, we recommend using TrustWallet and its built in
              browser.
            </p>
          </div>
        </div>
        <div
          className="crypto-back"
          data-aos="fade-right"
          data-aos-anchor-placement="top-center"
          data-aos-duration="1500">
          <div className="step">
            <FillText color="black">Step2</FillText>
          </div>
          <div className="body">
            <div className="mask">
              <img src={icon6} alt="logo" />
              <m.h1
                initial={{ y: 30, opacity: 0 }}
                transition={{ delay: 0.1 }}
                whileInView={{ y: 0, opacity: 1 }}>
                Click on the "Buy $HLX"
              </m.h1>
            </div>
            <p>
              Click on the "Buy $HLX" button to enable Healix to connect with
              your wallet. Once connected, you can buy $HLX tokens using a
              selection of currencies from the BNB chain.
            </p>
          </div>
        </div>
        <div
          className="crypto-back"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          data-aos-duration="1500">
          <div className="step">
            <FillText color="black">Step3</FillText>
          </div>
          <div className="body">
            <div className="mask">
              <img src={icon7} alt="logo" />
              <m.h1
                initial={{ y: 30, opacity: 0 }}
                transition={{ delay: 0.1 }}
                whileInView={{ y: 0, opacity: 1 }}>
                Choose amount of $HLX
              </m.h1>
            </div>
            <p>
              Choose the amount of $HLX tokens you wish to buy or the amount of
              your assets you wish to spend and click "Buy now". If you don't
              have enough BNB please transfer it from any crypto exchange to
              your wallet address or buy more through Metamask or TrustWallet
              marketplace.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
