import { useSelector } from "react-redux";

/**LOADER COMPONENTS */
const Loader = () => {
  /**GET STATES FROM STORE */
  const { isLoading } = useSelector((state) => state.loading);
  /**IF isLoading IS TRUE SHOW LOADER*/
  if (isLoading) {
    return (
      <div className="page-loader2">
        <div className="spinner2"></div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Loader;
