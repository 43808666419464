import React, { useRef, useState } from 'react'
import { useScroll, useTransform } from 'framer-motion'

import FillText from '../../FillText/FillText'
import download from '../../../assest/download.png'
import droup from '../../../assest/Group(4).png'
import icon from '../../../assest/2.png'
import { m } from 'framer-motion'

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="acc">
      <div onClick={toggleAccordion} className="accodion_style">
        <strong>{title}</strong>
        {isOpen && <div className="accodion_body">{content}</div>}
      </div>
      <img src={download} alt="icon" onClick={toggleAccordion} />
    </div>
  )
}

const Accordion = () => {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  })
  const y = useTransform(scrollYProgress, [0, 1], [500, -500])
  const buildingY = useTransform(scrollYProgress, [0, 1], [100, -200])
  return (
    <div ref={ref} className="faq-start">
      <m.img
        style={{ y: buildingY }}
        src={icon}
        alt="icon"
        className="left_icon my_animation"
      />
      <m.img
        style={{
          y
        }}
        src={droup}
        alt="icon"
        className="right_icon my_animation"
      />
      <div
        className="container faq"
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1500">
        <h1>
          <FillText color="black">FAQ</FillText>
        </h1>
        <h2>New to crypto?</h2>
        <>
          <AccordionItem
            title="How to buy $HLX using my desktop?"
            // content="First, ensure you have the MetaMask wallet installed as a browser extension in order to connect your wallet to the platform: https://metamask.io/download/"
            content={
              <ol class="tw-list-decimal tw-pl-4 tw-ml-4 tw-space-y-2">
                <li>
                  First, ensure you have the MetaMask wallet installed as a
                  browser extension in order to connect your wallet to the
                  platform:{' '}
                  <a
                    href="https://metamask.io/download/"
                    class="tw-text-blue-500 tw-underline">
                    https://metamask.io/download/
                  </a>
                </li>
                <li>
                  Once connected, you can buy BNB or a stablecoin such as USDT
                  via card. Just click on "Buy" and it will open the MetaMask
                  marketplace, at:{' '}
                  <a
                    href="https://portfolio.metamask.io/"
                    class="tw-text-blue-500 tw-underline">
                    https://portfolio.metamask.io/
                  </a>
                </li>
                <li>
                  Once you have the necessary funds in your wallet, head over to
                  the Healix platform at:{' '}
                  <a
                    href="https://healixprotocol.io"
                    class="tw-text-blue-500 tw-underline">
                    https://healixprotocol.io
                  </a>
                </li>
                <li>
                  Click on the "Buy Now" button to enable Healix to connect with
                  your wallet. Once connected, you can buy $HLX tokens using a
                  selection of currencies from the BNB chain.
                </li>
                <li>
                  Choose the amount of $HLX tokens you wish to buy or the amount
                  of your assets you wish to spend and click "Buy now". If you
                  don't have enough BNB, please transfer it from any crypto
                  exchange to your wallet address or buy more through MetaMask
                  marketplace.
                </li>
              </ol>
            }
            img={download}
          />
          <AccordionItem
            title="How to buy $HLX using my mobile?"
            // content="First, ensure you have the MetaMask wallet installed as a browser extension in order to connect your wallet to the platform: https://metamask.io/download/"
            content={
              <ol class="tw-list-decimal tw-pl-4 tw-ml-4 tw-space-y-2">
                <li>
                  If you are purchasing on mobile, we recommend using Trust
                  Wallet and connecting through the WalletConnect:
                  <a
                    href="https://community.trustwallet.com/t/how-to-use-walletconnect-with-trust-wallet/36247"
                    class="tw-text-blue-500 tw-underline">
                    https://community.trustwallet.com/t/how-to-use-walletconnect-with-trust-wallet/36247
                  </a>
                </li>
                <li>
                  Download the TrustWallet app onto your mobile device from the
                  relevant app store (Apple App Store, Google Play Store, etc).
                </li>
                <li>
                  Once your wallet is activated, you need to ensure you have the
                  BNB Chain available in your wallet. If you don’t, you can add
                  it to your wallet by following the steps at:
                  <a
                    href="https://community.trustwallet.com"
                    class="tw-text-blue-500 tw-underline">
                    https://community.trustwallet.com
                  </a>
                </li>
                <li>
                  If you don't have any assets in the wallet, please transfer
                  them from any crypto exchange to your wallet address or buy
                  more through Trust Wallet marketplace simply by clicking on
                  the “Buy” button. Once you have the necessary funds in your
                  wallet, head over to the Healix platform at:
                  <a
                    href="https://healixprotocol.io"
                    class="tw-text-blue-500 tw-underline">
                    https://healixprotocol.io
                  </a>
                </li>
                <li>
                  Click on the "Buy Now" button to enable Healix to connect with
                  your wallet. Once connected, you can buy $HLX tokens using a
                  selection of currencies from the ETH chain.
                </li>
                <li>
                  Choose the amount of $HLX tokens you wish or select how much
                  assets you wish to spend and click "Buy now".
                </li>
              </ol>
            }
            img={download}
          />
        </>
        <h2 style={{ marginTop: '40px' }}>About Healix</h2>
        <>
          <AccordionItem
            title="How does Healix use AI and IoT for health monitoring?"
            content="By integrating cutting-edge technologies such as Artificial Intelligence (AI) and Internet of Things (IoT), the platform empowers users to monitor and manage their health with unprecedented accuracy and efficiency."
            img={download}
          />

          <AccordionItem
            title=" Why did Healix choose the BNB chain?"
            content="The decision to build the HLX token ecosystem on Binance Smart Chain (BSC) stems from its unparalleled advantages over other blockchain infrastructures. BSC is the foundation for Healix Protocol, providing a robust and efficient platform to support seamless transactions, decentralized governance, and innovative incentive mechanisms. "
            img={download}
          />
          <AccordionItem
            title="Can we earn by being a part of Healix?"
            content="Yes, The Health Staking Contract is a mechanism designed to empower users in the health and wellness space to stake their HLX tokens and receive rewards generated from a combination of the staking rewards pool and a percentage of contributions from individuals actively pursuing their health and fitness goals through the Healix Ecosystem."
            img={download}
          />
          <AccordionItem
            title="How can we improve our health by using Healix?"
            content="Within the HEALIX platform, the Health Quotient (HQ) stands as a novel feature, providing users with real-time insights into their overall health and wellness. HQ is a dynamic score derived from a comprehensive analysis of various biomarkers and health parameters, enabling individuals to gauge their health status with precision and accuracy."
            img={download}
          />
          <AccordionItem
            title="What are the utilities of the HLX token?"
            content="The HLX token lies at the heart of the Healix Protocol, serving as a versatile and essential component of the ecosystem. With a range of use cases designed to incentivize engagement, facilitate transactions, and enhance platform security, the HLX token plays a pivotal role in driving the adoption and growth of the Healix platform."
            img={download}
          />
          <AccordionItem
            title="Does Healix provide data security?"
            content="Yes, Healix addresses data silos and privacy concerns by implementing robust data security protocols and empowering users to control their health data. Through encrypted blockchain technology and user-centric data management tools, Healix ensures the confidentiality and integrity of personal health information, fostering trust and compliance with privacy regulations."
            img={download}
          />
        </>
      </div>
    </div>
  )
}

export default Accordion
