import './join.css'

import React, { useRef } from 'react'
import { m, useScroll, useTransform } from 'framer-motion'

import Image from '../../Image/Image'
import dollar from '../../../assest/dollar.png'
import insta_logo from '../../../assest/insta_logo.png'
import join from '../../../assest/join.png'
import medium from '../../../assest/medium.png'
import { openSocialMedia } from '../../../Services/comon.service'
import telegram_logo from '../../../assest/telegram_logo.png'
import x_logo from '../../../assest/x_logo.png'

export default function Join() {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  })

  return (
    <div ref={ref} className="tw-rounded-[0px_0px_32px_32px]">
      <m.img
        src={dollar}
        alt=""
        className="tw-hidden md:tw-block"
        style={{
          y: useTransform(scrollYProgress, [0, 1], ['400%', '-100%'])
        }}
      />
      <JoinBanner />
    </div>
  )
}
export function JoinBanner() {
  return (
    <div className="container join">
      <img src={join} alt="join" className="join-img" />
      <div className="journey tw-p-5">
        <div className="tw-mb-5 tw-flex tw-gap-5 tw-justify-center">
          <div
            onClick={() => {
              openSocialMedia('x')
            }}
            className="tw-rounded-full tw-bg-[#fff] tw-p-4 tw-w-fit tw-h-fit  tw-cursor-pointer">
            <img src={x_logo} alt="twitter" className="tw-w-5" />
          </div>
          <div
            onClick={() => {
              openSocialMedia('telegram')
            }}
            className="tw-rounded-full tw-bg-[#fff] tw-p-4 tw-w-fit tw-h-fit  tw-cursor-pointer">
            <img src={telegram_logo} alt="telegram" className="tw-w-5" />
          </div>
          <div
            onClick={() => {
              openSocialMedia('instagram')
            }}
            className="tw-rounded-full tw-bg-[#fff] tw-p-4 tw-w-fit tw-h-fit  tw-cursor-pointer">
            <img src={insta_logo} alt="twitter" className="tw-w-5" />
          </div>
          <div
            onClick={() => {
              openSocialMedia('medium')
            }}
            className="tw-rounded-full tw-bg-[#fff] tw-p-4 tw-w-fit tw-h-fit  tw-cursor-pointer">
            <img src={medium} alt="medium" className="tw-w-5" />
          </div>
        </div>
        <h1>
          <div>
            <m.div
              initial={{
                y: 100,
                opacity: 0
              }}
              whileInView={{
                y: 0,
                opacity: 1
              }}>
              Be a part of the Healix
            </m.div>
          </div>
          <div>
            <m.div
              initial={{
                y: 100,
                opacity: 0
              }}
              whileInView={{
                y: 0,
                opacity: 1
              }}
              transition={{
                delay: 0.1
              }}>
              Journey!
            </m.div>
          </div>
        </h1>
        <p>
          Healix employs advanced cryptographic techniques to secure and protect
          sensitive health data. The decentralized nature of the blockchain
          ensures that users have control over their medical information while
          maintaining privacy and confidentiality.
        </p>
      </div>
    </div>
  )
}
