import './covered.css'

import React, { useRef } from 'react'
import { m, useScroll, useTransform } from 'framer-motion'

import FillText from '../../FillText/FillText'
import Image from '../../Image/Image'
import X from '../../../assest/3X.png'
import arrowbutton from '../../../assest/arrowbutton.png'
import brand from '../../../assest/brand.png'
import building2 from '../../../assest/bg-component2.png'

export default function Covered() {
  const section = useRef(null)
  const { scrollYProgress: sectionProgress } = useScroll({
    target: section,
    offset: ['start end', 'end start']
  })
  const building1Y = useTransform(sectionProgress, [0, 1], ['50%', '-50%'])

  return (
    <div ref={section} className="container">
      <m.img
        style={{
          y: building1Y
        }}
        src={building2}
        alt="logo"
        className="tw-absolute tw-max-w-[800px] tw-z-[-1] tw-right-0 tw-hidden md:tw-block"
      />
      <div className="parent-container ">
        <div
          className="second-div2 trans"
          data-aos="fade-left"
          data-aos-anchor-placement="top-center"
          data-aos-duration="1500">
          <h2>HLX to lead Web3 economy in healthcare</h2>
          <p>
            With an estimated value exceeding USD 8 trillion, the healthcare
            sector is one of the largest and most rapidly evolving industries
            worldwide. While the overall healthcare market is vast and
            multifaceted, the preventive healthcare space stands out as a
            particularly promising segment poised for exponential growth.
          </p>
          <p>
            Preventive healthcare technologies and services industry size was
            USD 251.2 billion in 2023 and is estimated to reach USD 605.3
            billion by 2032 owing to the growing prevalence of chronic diseases,
            such as diabetes and obesity. HLX Token is uniquely poised to grab
            this market.
          </p>
        </div>
        <div className="first-div1">
          <div className="flex">
            <div
              className="sector"
              data-aos="fade-right"
              data-aos-anchor-placement="top-center"
              data-aos-duration="1500">
              <h1>$8T</h1>
              <p>Estimated value of the healthcare sector</p>
            </div>
            <div
              className="sector"
              data-aos="fade-left"
              data-aos-anchor-placement="top-center"
              data-aos-duration="1500">
              <h1>$251B</h1>
              <p>
                Current preventive healthcare technologies and services industry
                size
              </p>
            </div>
          </div>
          <div
            className="threex"
            data-aos="fade-right"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500">
            <img src={X} alt="3x" />
            <p>
              Growth of the preventive healthcare technologies and services
              industry size owing to the growing prevalence of chronic diseases,
              such as diabetes and obesity. HLX Token is uniquely poised to grab
              this market.
            </p>
          </div>
        </div>
      </div>
      <div className="section-3 covered_section tw-pb-20">
        <div className="cover">
          <div
            className="globalbox"
            data-aos="fade-left"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500">
            <p>
              Connecting health and wellness institutions and practitioners with
              health seekers, which provides options for seekers.
            </p>
            <hr className="globalbox-line"></hr>
            <h2>
              <div>
                <m.div
                  initial={{
                    y: 100,
                    opacity: 0
                  }}
                  whileInView={{
                    y: 0,
                    opacity: 1
                  }}
                  transition={{
                    delay: 0.1
                  }}>
                  Global Blockchain
                </m.div>
              </div>
              <div>
                <m.div
                  initial={{
                    y: 100,
                    opacity: 0
                  }}
                  whileInView={{
                    y: 0,
                    opacity: 1
                  }}
                  transition={{
                    delay: 0.18
                  }}>
                  based ecosystem
                </m.div>
              </div>
            </h2>
          </div>
          <div
            className="Healix-box"
            data-aos="fade-right"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500">
            <Image src={brand} alt="brand" />
          </div>
        </div>
        <div
          className="Engagement"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          data-aos-duration="1500">
          <div className="powerful">
            <h2>
              <m.div
                initial={{ y: 100 }}
                whileInView={{ y: 0 }}
                transition={{ delay: 0.1 }}>
                Powerful Incentivized
              </m.div>
              <m.div
                initial={{ y: 100 }}
                whileInView={{ y: 0 }}
                transition={{ delay: 0.2 }}>
                Engagement
              </m.div>
            </h2>
          </div>
          <div className="engage">
            <p>Engage with rewards and loyalty programs.</p>
          </div>
        </div>
        <div className="global">
          <div
            className="real-time"
            data-aos="fade-right"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500">
            <h2>Real-Time Monitoring & Guidance</h2>
            <hr className="globalbox-line"></hr>
            <p>Instant health monitoring personalized advice.</p>
          </div>
          <div
            className="Global-Community"
            data-aos="fade-left"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500">
            <h2>
              <FillText color="black" className="ms-auto">
                Global Web3
              </FillText>

              <FillText color="black" className="ms-auto">
                Community
              </FillText>
            </h2>
            <hr className="globalbox-line-white"></hr>
            <p>
              Join our global Web3 community, where innovation, collaboration,
              and blockchain technology unite to shape the future.
            </p>
          </div>
        </div>

        <div className="cover2">
          <div
            className="Healix-box2"
            data-aos="fade-right"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500"></div>
          <div
            className="globalbox2"
            data-aos="fade-left"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500">
            <p>
              Crafting a global healthcare network that prioritizes affordable,
              accessible self-care for all, powered by data science and AI.
            </p>
            <hr className="globalbox-line"></hr>
          </div>
        </div>
        <div className="Benefits">
          <div
            className="Partner-Benefits"
            data-aos="fade-right"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500">
            <p>
              Making it beneficial for users and health insurance companies.
            </p>
            <hr className="globalbox-line-white"></hr>
            <h2>
              <FillText color="black">Exclusive</FillText>{' '}
              <FillText color="black"> Partner Benefits</FillText>
            </h2>
          </div>
          <div
            className="Global-Healthcare"
            data-aos="fade-left"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500">
            <h2>
              <FillText>Global Healthcare</FillText>
              <FillText>Access</FillText>
            </h2>
            <hr className="globalbox-line"></hr>
            <p>
              Seamless access to global healthcare services and professionals.
            </p>
          </div>
        </div>
        <m.img
          initial={{
            y: '20%'
          }}
          animate={{
            y: '0%'
          }}
          transition={{
            type: 'bounce',
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 3
          }}
          src={arrowbutton}
          alt="arrowbutton"
          className="arrowbutton my_animation"
        />
      </div>
    </div>
  )
}
