import './roadmap.css'

import React, { useRef } from 'react'
import { m, useScroll, useTransform } from 'framer-motion'

import FillText from '../../FillText/FillText'
import cardBg from '../../../assest/card-bg.png'
import cardBgPlain from '../../../assest/card-bg-plain.png'
import logowhite from '../../../assest/logowhite.png'
import q1 from '../../../assest/q1.png'
import q2 from '../../../assest/q2.png'
import q3 from '../../../assest/q3.png'
import q4 from '../../../assest/q4.png'
import q5 from '../../../assest/q5.png'
import q5c from '../../../assest/q5c.png'
import q5crop from '../../../assest/q5crop.png'
import roadmap from '../../../assest/roadmap-bg.png'

export default function Roadmap() {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end center']
  })
  const clipPath = useTransform(
    scrollYProgress,
    [0, 1],
    [
      'polygon(0 0, 0% 0, 0% 100%, 0% 100%)',
      'polygon(0 0, 100% 0, 100% 100%, 0% 100%)'
    ]
  )
  const roadmapCards = [
    {
      year: '2024',
      tag: 'Q1',
      cardImageSrc: cardBg,
      ImageSrc: q1,
      events: ['Website Launch', 'Whitepaper Released', 'Presale Commences']
    },
    {
      year: '',
      tag: 'Q2',
      ImageSrc: q2,
      cardImageSrc: cardBgPlain,
      events: [
        'Smart Contract Audit Completed',
        'Smart Contract Deployed',
        'HLX Ambassador Program',
        'HLX Airdrop',
        'HLX App Development'
      ]
    },
    {
      year: '',
      tag: 'Q3',
      ImageSrc: q3,
      cardImageSrc: cardBgPlain,
      events: [
        'Presale concludes',
        'HLX Partnership Announcements',
        'DEX Listing',
        'Tier 2 CEX Listing',
        'Official HLX App released',
        'Official HLX Merchandise unveiled',
        'HLX Rewards Program unveiled',
        'HLX User subscriptions unveiled',
        'HLX AI Health Trainer unveiled'
      ]
    },
    {
      year: '',
      tag: 'Q4',
      cardImageSrc: cardBgPlain,
      ImageSrc: q4,
      events: [
        'Tier 1 CEX Listing',
        'Launch of HLX Health Wearables',
        'Launch of HLX Generative AI Model',
        'Partnerships with top hospitals and wellness institutions',
        'HLX Partner Program released',
        'Testing of Healix Genome Sequencing Program commences'
      ]
    }
  ]

  return (
    <div className="healix tw-bg-[url('../../../assest/roadmap-bg.png')] tw-bg-cover tw-bg-no-repeat tw-mb-10">
      {/* <img src={roadmap} alt="roadmap" className="roadmap-bg" /> */}
      <div className="container">
        <div
          className="flex buy-hlx"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          data-aos-duration="1500">
          <img src={logowhite} alt="logo" />

          <h1 ref={ref}>
            Roadmap
            <m.div style={{ clipPath }}>Roadmap</m.div>
          </h1>
        </div>
        {roadmapCards.map(({ year, ImageSrc, cardImageSrc, events, tag }) => {
          return (
            <div
              key={tag}
              className="tw-relative tw-rounded-[3rem] tw-overflow-clip tw-my-10 md:tw-bg-transparent tw-bg-[#0f0f0f]">
              <img
                className="tw-w-full md:tw-block tw-hidden"
                src={cardImageSrc}
                alt="roadmap"
              />
              <div className="tw-w-full tw-h-full md:tw-absolute tw-z-[2] tw-p-8 tw-right-0 tw-bottom-0">
                {year && (
                  <h2 className="tw-text-[#fff] tw-mt-0 md:tw-text-3xl md:tw-text-5xl tw-font-bold">
                    <FillText>{year}</FillText>
                  </h2>
                )}
                {/* <div className=" md:tw-hidden tw-w-full"> */}
                <h3 className="tw-ml-auto tw-mb-auto tw-m-0 text-clip tw-right-5 tw-top-5 tw-w-fit tw-mb-3 md:tw-hidden">
                  {tag}
                </h3>
                {/* </div> */}

                <div
                  className={`tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-[30px] md:tw-absolute tw-z-[4] tw-w-full tw-right-0 tw-bottom-0 md:tw-p-8 h-full ${
                    cardImageSrc === cardBg ? 'md:tw-h-[85%]' : 'md:tw-h-[100%]'
                  }`}>
                  <img
                    src={ImageSrc}
                    alt="roadmap"
                    className="tw-rounded-2xl"
                  />
                  <div className="">
                    <ul className="tw-text-[#fff] md:tw-text-xl tw-text-lg tw-pl-3 tw-pl-10">
                      {events.map(_ => {
                        return <li key={_}>{_}</li>
                      })}
                    </ul>
                  </div>
                  <h3 className="tw-ml-auto tw-mb-auto tw-hidden md:tw-block tw-m-0 text-clip tw-right-5 tw-top-5">
                    {tag}
                  </h3>
                </div>
              </div>
            </div>
          )
        })}
        <div className="tw-relative tw-rounded-[3rem] tw-overflow-clip tw-my-10 md:tw-bg-transparent tw-bg-[#0f0f0f]">
          <img
            className="tw-w-full md:tw-block tw-hidden"
            src={q5c}
            alt="roadmap"
          />
          <div className="tw-w-full tw-h-full md:tw-absolute tw-z-[2] tw-p-8 tw-right-0 tw-bottom-0">
            <h2 className="tw-text-[#fff] tw-mt-0 md:tw-text-3xl md:tw-text-5xl tw-font-bold">
              <FillText>2025</FillText>
            </h2>
            <ul className="tw-text-[#fff] md:tw-text-xl tw-text-lg tw-mt-10 tw-pl-3">
              <li>Launch of HLX Physical Wellness Centre</li>
              <li>Partnering with global sporting events</li>
              <li>Official launch of Healix Genome Sequencing Program</li>
              <li>Hosting the first HLX Global Wellness Summit</li>
              <li>Hitting a million subscribers on the HLX App</li>
              <li>
                Launch of Healix Vault: A secure storage and management platform
                for users' health data using SBTs (Soul Bound Tokens) and DID
                (Decentralized Identities)
              </li>
            </ul>
            <img
              className="tw-w-full md:tw-hidden"
              src={q5crop}
              alt="roadmap"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
