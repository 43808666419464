import { LoaderSlice } from '../features/loading.slice'
import { ReferralSlice } from '../features/referral.slice'
import { combineReducers } from '@reduxjs/toolkit'
import { loginSlice } from '../Wallet/wallet'
import { themeSlice } from '../Wallet/theme'

export const rootReducer = combineReducers({
  loading: LoaderSlice.reducer,
  login: loginSlice.reducer,
  referral: ReferralSlice.reducer,
  theme: themeSlice.reducer
})
