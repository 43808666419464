import React, { useEffect } from 'react'

import { setReferredId } from '../redux/features/referral.slice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

function ReferralPage() {
  const navigate = useNavigate()
  const { referral } = useParams()
  const dispatch = useDispatch()
  dispatch(setReferredId(referral))
  useEffect(() => {
    navigate('/')
  }, [])
  return <></>
}

export default ReferralPage
