import { CounterText } from './cards'
import React from 'react'
import total_bg from '../../assest/total-hlx-bg.png'

function TotalCard({ stakedData, referralBonus }) {
  return (
    <div
      style={{
        backgroundImage: `url(${total_bg})`
      }}
      className="tw-min-h-96 tw-bg-cover tw-bg-no-repeat tw-my-10 tw-rounded-3xl tw-p-10 md:tw-p-14 tw-flex tw-flex-col tw-gap-8 md:tw-items-end tw-items-center tw-text-white tw-relative tw-overflow-clip">
      <div className="tw-absolute md:tw-bg-transparent tw-bg-black/40 tw-w-full tw-h-full tw-m-auto tw-right-0 tw-left-0 tw-top-0 tw-bottom-0 tw-z-[0]" />
      <h2 className="tw-z-10 tw-text-5xl md:tw-w-1/2 tw-text-center tw-my-0 ">
        Total HLX
      </h2>
      <h2 className="tw-z-10 text-clip !tw-not-italic !tw-font-medium  !tw-text-6xl md:!tw-text-7xl md:tw-w-1/2 tw-text-center tw-my-0">
        <CounterText
          end={(
            Number(stakedData?.bonusInHLX || 0) +
            Number(referralBonus) +
            Number(stakedData?.eTuktukAmount || stakedData?.eHLXAmount || 0)
          ).toFixed(4)}
          decimals={4}
        />
      </h2>
      <div className="tw-z-10 tw-space-y-4 tw-text-black md:tw-w-1/2">
        <div className="tw-bg-white tw-px-8 md:tw-w-full tw-flex tw-justify-between tw-rounded-md tw-gap-10">
          <p>Bonus</p>
          <p>
            <CounterText
              end={Number(stakedData?.bonusInHLX)?.toFixed(4)}
              decimals={4}
            />
          </p>
        </div>
        <div className="tw-bg-white tw-px-8 md:tw-w-full tw-flex tw-justify-between tw-rounded-md tw-gap-10">
          <p>Referral Bonus</p>
          <p>
            <CounterText end={Number(referralBonus)?.toFixed(4)} decimals={4} />
          </p>
        </div>
      </div>
    </div>
  )
}

export default TotalCard
