import toast from "react-hot-toast";

/** OPTIONS FOR TOAST */
const options = {
  position: "top-center",
  autoClose: 8000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  // duration: 5000,
  className: "toster-bar",
};

/** TOAST COMPONENT FOR NOTIFICATIONS */
class Toaster {
  /** FOR SUCCESS */
  success = (message) => {
    toast.success(message, options);
  };

  /** FOR ERROR */
  error = (message) => {
    toast.error(message, options);
  };

  /** FOR INFO */
  info = (message) => {
    toast.info(message, options); // Use toast.info here
  };
}

const toaster = new Toaster();
export default toaster;
