import {
  contractAddress,
  ethContractAddress,
  ethTokenAddress,
  getError,
  tokenAddress
} from './comon.service'

import { Contract } from 'ethers'
import HEALIX_ABI from '../abi/HEALIX_ABI.json'
import HEALIX_ETH_ABI from '../abi/HEALIX_ETH_ABI.json'
import USDT_ABI from '../abi/usdt.json'
import Web3 from 'web3'
import { callApiPostNew } from '../redux/Actions/api.action'
import { ethers } from 'ethers'
import store from '../redux/store/store'
import toaster from '../component/Toast'
import tokenAbi from '../abi/tokenABI.json'

// const ethers = require("ethers");

export const toWeiConvert = async amount => {
  return await Web3.utils.toWei(amount?.toString(), 'ether')
}

export const fromWeiConvert = async amount => {
  return await Web3.utils.fromWei(amount?.toString(), 'ether')
}

export const fromWeiUSDT = amount =>
  Web3.utils.fromWei(amount?.toString(), 'mwei')
export const toWeiUSDT = amount => Web3.utils.toWei(amount?.toString(), 'mwei')

export const callEthers = async walletProvider => {
  const selectedNetwork = store.getState().login.network
  const selectedNetworkReducer = selectedNetwork.map(data => data.chainId)
  let ethersObject

  try {
    if (ethersObject) {
      const chainId = await ethersObject?.getChainId()
      if (!selectedNetworkReducer.includes(Number(chainId?.chainId))) {
        await walletProvider.send('wallet_switchEthereumChain', [
          { chainId: selectedNetwork.chainIdHex }
        ])
      }
      return ethersObject
    } else if (walletProvider) {
      const provider = new ethers.BrowserProvider(walletProvider)
      const signer = provider.getSigner()
      ethersObject = signer
      const chainId = await provider?.getNetwork()

      if (!selectedNetworkReducer.includes(Number(chainId?.chainId))) {
        await provider.send('wallet_switchEthereumChain', [
          { chainId: selectedNetwork?.chainIdHex }
        ])
      }

      return signer
    }
  } catch (err) {
    console.log(err, 'error')
    if (err.code === 4902 || err.code === 4100 || err.code === undefined) {
      const selectedNetwork = store.getState().login.network
      const selectedNetworkRow = selectedNetwork[0]
      await walletProvider.send('wallet_addEthereumChain', [
        {
          chainId: selectedNetworkRow.chainIdHex,
          chainName: selectedNetworkRow.symbol,
          nativeCurrency: {
            name: selectedNetworkRow.symbol,
            symbol: selectedNetworkRow.symbol,
            decimals: Number(selectedNetworkRow.decimals)
          },
          rpcUrls: [selectedNetworkRow.rpc],
          blockExplorerUrls: [selectedNetworkRow.explorerUrl]
        }
      ])
    }
  }
}

export const createInstance = async (abi, contractAddress, walletProvider) => {
  try {
    const ethersInstance = await callEthers(walletProvider)
    const contract = new Contract(contractAddress, abi, ethersInstance)
    return contract
  } catch (error) {
    console.error(error, 'error')
    throw error
  }
}

export const usdtAllowance = async (data, walletProvider) => {
  try {
    const contract = await createInstance(
      USDT_ABI,
      ethTokenAddress,
      walletProvider
    )

    if (contract) {
      let allowanceWei = await contract?.allowance(
        data?.walletAddress,
        data?.contract
      )

      const allowance = fromWeiUSDT(allowanceWei?.toString())
      const amount = fromWeiUSDT(data?.amount?.toString())
      //let limit = '0xfffffffffffffffffffffffffff'

      if (Number(allowance) < Number(amount)) {
        let gas = await contract?.estimateGas?.approve(
          data?.contract,
          data?.amount?.toString(),
          {
            from: data?.walletAddress
          }
        )
        const res = await contract?.approve(
          data?.contract,
          data?.amount?.toString(),
          {
            gasLimit: gas?.toString()
          }
        )
        const result = await res.wait()
        if (result) {
          const allowanceinWei = await contract?.allowance(
            data?.walletAddress,
            data?.contract
          )
          const finalAllowance = fromWeiUSDT(allowanceinWei?.toString())

          return finalAllowance
        }
        return result
      } else {
        return allowance
      }
    }
  } catch (error) {
    console.log(error, 'error')
    toaster.error(getError(error))
  }
}

export const giveTokenAllowance = async (data, walletProvider) => {
  try {
    const contract = await createInstance(
      tokenAbi,
      tokenAddress,
      walletProvider
    )
    let limit = '0xfffffffffffffffffffffffffff'
    if (contract) {
      let allowanceWei = await contract?.allowance(
        data?.walletAddress,
        data?.contract
      )
      const allowance = await fromWeiConvert(allowanceWei?.toString())

      if (Number(allowance) <= Number(data?.amount)) {
        let gas = await contract?.estimateGas?.approve(data?.contract, limit, {
          from: data?.walletAddress
        })
        const res = await contract?.approve(data?.contract, limit, {
          gasLimit: gas?.toString()
        })
        const result = await res.wait()
        if (result) {
          const allowanceinWei = await contract?.allowance(
            data?.walletAddress,
            data?.contract
          )
          const finalAllowance = await fromWeiConvert(
            allowanceinWei?.toString()
          )

          return finalAllowance
        }
        return result
      } else {
        return allowance
      }
    }
  } catch (error) {
    console.log(error, 'error')
    toaster.error(getError(error))
  }
}

export const buyWithToken = async (
  amount,
  walletProvider,
  walletAddress,
  referralID,
  dispatch
) => {
  try {
    const contract = await createInstance(
      HEALIX_ABI,
      contractAddress,
      walletProvider
    )

    if (contract) {
      let gas = await contract?.estimateGas?.buyWithToken(amount)
      const res = await contract?.buyWithToken(amount, {
        gasLimit: gas?.toString()
      })
      if (referralID !== null) {
        await dispatch(
          callApiPostNew(
            'PURCHASE',
            {
              walletAddress: walletAddress,
              referralCode: referralID,
              transactionHash: res.hash
            },
            '',
            false
          )
        )
      }

      const result = await res.wait()
      return result
    }
  } catch (error) {
    console.log(error, 'error')
    toaster.error(getError(error))
  }
}

export const buyWithUsdt = async (
  amount,
  walletProvider,
  walletAddress,
  referralID,
  dispatch
) => {
  try {
    const contract = await createInstance(
      HEALIX_ETH_ABI,
      ethContractAddress,
      walletProvider
    )

    if (contract) {
      let gas = await contract?.estimateGas?.receiveToken(
        ethTokenAddress,
        amount
      )
      const res = await contract?.receiveToken(ethTokenAddress, amount, {
        gasLimit: gas?.toString()
      })

      if (referralID !== null) {
        await dispatch(
          callApiPostNew(
            'PURCHASE',
            {
              walletAddress: walletAddress,
              referralCode: referralID,
              transactionHash: res.hash
            },
            '',
            false
          )
        )
      }

      const result = await res.wait()
      return result
    }
  } catch (error) {
    console.log(error, 'error')
    toaster.error(getError(error))
  }
}

export const buyHelixWithBNB = async (
  amount,
  walletProvider,
  walletAddress,
  referralID,
  dispatch
) => {
  try {
    const contract = await createInstance(
      HEALIX_ABI,
      contractAddress,
      walletProvider
    )

    if (contract) {
      // Convert the amount to Wei
      const amountWei = await toWeiConvert(amount)
      let gas = await contract?.estimateGas?.buyWithBNB({
        value: amountWei
      })
      const res = await contract?.buyWithBNB({
        value: amountWei,
        gasLimit: gas?.toString()
      })

      if (referralID !== null) {
        await dispatch(
          callApiPostNew(
            'PURCHASE',
            {
              walletAddress: walletAddress,
              referralCode: referralID,
              transactionHash: res.hash
            },
            '',
            false
          )
        )
      }

      const result = await res.wait()
      return result
    }
  } catch (error) {
    console.error(error, 'error')
    toaster.error(getError(error))
  }
}

export const buyHelixWithETH = async (
  amount,
  walletProvider,
  walletAddress,
  referralID,
  dispatch
) => {
  try {
    const contract = await createInstance(
      HEALIX_ETH_ABI,
      ethContractAddress,
      walletProvider
    )

    const amountWei = await toWeiConvert(amount)

    let gas = await contract?.estimateGas?.receiveEther({
      value: amountWei
    })
    const txResult = await contract?.receiveEther({
      value: amountWei,
      gasLimit: gas?.toString()
    })

    if (referralID !== null) {
      await dispatch(
        callApiPostNew(
          'PURCHASE',
          {
            walletAddress: walletAddress,
            referralCode: referralID,
            transactionHash: txResult.hash
          },
          '',
          false
        )
      )
    }

    return await txResult.wait()
  } catch (error) {
    console.error(error, 'error')
    toaster.error(getError(error))
  }
}

export const getPrice = async walletProvider => {
  try {
    const contractInstance = await createInstance(
      HEALIX_ABI,
      contractAddress,
      walletProvider
    )
    console.log(contractInstance, 'contractInstance')
    if (contractInstance) {
      const res = await contractInstance.tokenPrice()
      const result = res.toString()
      return result
    }
  } catch (error) {
    console.error(error, 'error')
    // toaster.error(getError(error));
  }
}

export const getOwner = async walletProvider => {
  try {
    const contract = await createInstance(
      HEALIX_ABI,
      contractAddress,
      walletProvider
    )

    if (contract) {
      const result = await contract.owner()
      return result
    }
  } catch (error) {
    console.log(error, 'error')
  }
}

export const getUserTokenBalnce = async (address, walletProvider) => {
  try {
    const contract = await createInstance(
      tokenAbi,
      tokenAddress,
      walletProvider
    )
    if (contract) {
      const res = await contract.balanceOf(address)
      const result = res.toString()
      return result
    }
  } catch (error) {
    console.log(error, 'error')
  }
}

export const getUserUsdtBalnce = async (address, walletProvider) => {
  try {
    const contract = await createInstance(
      tokenAbi,
      ethTokenAddress,
      walletProvider
    )

    if (contract) {
      const res = await contract.balanceOf(address)
      const result = res.toString()
      return result
    }
  } catch (error) {
    console.log(error, 'error')
  }
}

export const getBNBBalance = async (userAddress, walletProvider) => {
  try {
    const signer = await callEthers(walletProvider)
    const balanceWei = await signer.provider.getBalance(userAddress)
    // Convert the balance from Wei to Ether
    const balance = await fromWeiConvert(balanceWei)
    return balance
  } catch (error) {
    console.log(error, 'error')
  }
}
