import './about.css'

import {
  ETH_NETWORKS,
  NETWORKS,
  addresFormate,
  contractAddress,
  ethContractAddress,
  ethTokenAddress,
  getTokenAndValue,
  handleNumberInputes,
  tokenAddress
} from '../../../Services/comon.service'
import React, { useEffect, useState } from 'react'
import {
  buyHelixWithBNB,
  buyHelixWithETH,
  buyWithToken,
  buyWithUsdt,
  fromWeiConvert,
  fromWeiUSDT,
  getBNBBalance,
  getPrice,
  getUserTokenBalnce,
  getUserUsdtBalnce,
  giveTokenAllowance,
  toWeiConvert,
  toWeiUSDT,
  usdtAllowance
} from '../../../Services/Contract.service'
import { setBnbPrice, setPaymentType } from '../../../redux/Wallet/wallet'
import { useDispatch, useSelector } from 'react-redux'

import { callApiGetMethod } from '../../../redux/Actions/api.action'
import icon from '../../../assest/icon.png'
import icon1 from '../../../assest/usdt-bnb.png'
import icon2 from '../../../assest/usdt-eth.png'
import icon3 from '../../../assest/eth-icon.png'
import icon4 from '../../../assest/icon4.png'
import profile from '../../../assest/profile.png'
import { setLoading } from '../../../redux/features/loading.slice'
import setting from '../../../assest/setting.png'
import toaster from '../../Toast'
import { useSwitchNetwork } from '@web3modal/ethers/react'
import { useUserWalletConnect } from '../../../Services/useWalletConnect'

export default function About() {
  const { walletProvider, open, isConnected, chainId } = useUserWalletConnect()
  const dispatch = useDispatch()
  const activePaymentMethod = useSelector(state => state?.login?.paymentType)
  const userAddress = useSelector(state => state?.login?.walletAddress)
  const [inputValue, setInputValue] = useState('')
  const [healixPrice, setHealixPrice] = useState(0.03)
  const [bnbPrice, setBNBPrice] = useState('')
  const [ethPrice, setETHPrice] = useState('')
  const [raised, setRaised] = useState({})
  const { switchNetwork } = useSwitchNetwork()
  const referralID = useSelector(state => state?.referral?.referralCode)

  const paymentMethods = [
    { icon: icon, value: 'BNB' },
    { icon: icon1, value: 'USDT(BEP20)' },
    { icon: icon3, value: 'ETH' },
    { icon: icon2, value: 'USDT' }
  ]
  const handleButtonClick = value => {
    dispatch(setPaymentType(value))
    let activeChainID

    if (value.value === 'USDT' || value.value === 'ETH') {
      activeChainID = ETH_NETWORKS.chainId
    } else {
      activeChainID = NETWORKS.chainId
    }

    if (chainId !== activeChainID) {
      switchNetwork(activeChainID)
    }
    getBNBValueInUSD()
  }

  const handleInputChange = value => {
    setInputValue(value)
  }
  const getHealixPrice = async () => {
    try {
      const data = await getPrice(walletProvider)
      const priceinWay = await fromWeiConvert(data?.toString())
      healixPrice && setHealixPrice(priceinWay)
    } catch (error) {
      console.log(error, 'error')
    }
  }
  const getConvertedInputValue = inputValue => {
    if (inputValue < 0) return 0
    switch (activePaymentMethod?.value) {
      case 'BNB':
        return ((inputValue * bnbPrice) / healixPrice)?.toFixed(4)
      case 'ETH':
        return ((inputValue * ethPrice) / healixPrice)?.toFixed(4)
      default:
        return (inputValue / healixPrice)?.toFixed(4)
    }
  }

  const buyHealix = async () => {
    try {
      dispatch(setLoading(true))

      if (activePaymentMethod?.value === 'BNB') {
        // BNB value processing
        const userBnb = await getUserNativeBalance()

        if (Number(inputValue) <= Number(userBnb)) {
          const data = await buyHelixWithBNB(
            inputValue?.toString(),
            walletProvider,
            userAddress,
            referralID,
            dispatch
          )
          if (data) {
            toaster.success(
              'Transaction successfully completed. Thank you for choosing Healix'
            )
            setInputValue('')
          }
        } else {
          toaster.error(
            `Oops! It looks like there's not enough BNB in your wallet at the moment. Please consider adding more to continue.`
          )
        }
      } else if (activePaymentMethod?.value === 'USDT(BEP20)') {
        // BNB-USDT(BEP20) value processing
        const availableToken = await getTokenBalance()

        if (Number(availableToken) >= Number(inputValue)) {
          let allowance = await giveTokenAllowance(
            {
              walletAddress: userAddress,
              contract: contractAddress,
              contractType: 'token',
              tokenAddress: tokenAddress,
              amount: Number(inputValue)
            },
            walletProvider
          )

          if (Number(allowance) >= Number(inputValue)) {
            const wei = await toWeiConvert(inputValue)
            const byToken = await buyWithToken(
              wei,
              walletProvider,
              userAddress,
              referralID,
              dispatch
            )
            if (byToken) {
              toaster.success(
                'Transaction successfully completed. Thank you for choosing Healix'
              )
              setInputValue('')
            }
          } else {
            toaster.error(
              `Oops! It looks like you are not approved the token for this transaction. Please consider approving it first.`
            )
          }
        } else {
          toaster.error(
            `Oops! It looks like there's not enough USDT(BEP20) in your wallet at the moment. Please consider adding more to continue.`
          )
        }
      } else if (activePaymentMethod?.value === 'ETH') {
        // ETH value processing
        const userBnb = await getUserNativeBalance()

        if (Number(inputValue) <= Number(userBnb)) {
          const data = await buyHelixWithETH(
            inputValue?.toString(),
            walletProvider,
            userAddress,
            referralID,
            dispatch
          )
          if (data) {
            toaster.success(
              'Transaction successfully completed. Thank you for choosing Healix'
            )
            setInputValue('')
          }
        } else {
          toaster.error(
            `Oops! It looks like there's not enough ETH in your wallet at the moment. Please consider adding more to continue.`
          )
        }
      } else if (activePaymentMethod?.value === 'USDT') {
        // ETH-USDT value processing
        const availableUsdt = await getUsdtBalance()
        const weiAmount = await toWeiUSDT(inputValue)
        if (Number(availableUsdt) >= Number(inputValue)) {
          let allowance = await usdtAllowance(
            {
              walletAddress: userAddress,
              contract: ethContractAddress,
              contractType: 'token',
              tokenAddress: ethTokenAddress,
              amount: Number(weiAmount)
            },
            walletProvider
          )

          if (Number(allowance) >= Number(inputValue)) {
            const usdtToken = await buyWithUsdt(
              weiAmount,
              walletProvider,
              userAddress,
              referralID,
              dispatch
            )
            if (usdtToken) {
              toaster.success(
                'Transaction successfully completed. Thank you for choosing Healix'
              )
              setInputValue('')
            }
          } else {
            toaster.error('Insufficient allowance')
          }
        } else {
          toaster.error(
            `Oops! It looks like there's not enough USDT in your wallet at the moment. Please consider adding more to continue.`
          )
        }
      }
    } catch (error) {
      toaster.error('Transaction failed')
      console.log(error, 'error')
    } finally {
      dispatch(setLoading(false))
    }
  }
  // Call the function to get the BNB price'
  const getUserNativeBalance = async () => {
    try {
      if (userAddress) {
        const userBnbBalance = await getBNBBalance(userAddress, walletProvider)
        return Number(userBnbBalance)?.toFixed(4)
      } else return 0
    } catch (error) {
      console.log(error, 'getUserNativeBalance error')
    }
  }

  const getTokenBalance = async () => {
    try {
      const data = await getUserTokenBalnce(userAddress, walletProvider)
      const balanceOf = await fromWeiConvert(data)
      return balanceOf
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const getUsdtBalance = async () => {
    try {
      const data = await getUserUsdtBalnce(userAddress, walletProvider)
      //console.log(data, 'data')
      const balanceOf = fromWeiUSDT(data)
      return balanceOf
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const getBNBValueInUSD = async () => {
    try {
      const res = await dispatch(
        callApiGetMethod('BNB_PRICE', '', false, false)
      )
      if (!res?.error) {
        // Extract the BNB price from the response
        dispatch(setBnbPrice(res?.data))
        setBNBPrice(res?.data)
      }
    } catch (error) {
      console.error('Error fetching BNB price:', error.message)
    }
  }
  // ETH price-need to update api
  const getETHValue = async () => {
    try {
      const res = await dispatch(
        callApiGetMethod('ETH_PRICE', '', false, false)
      )
      if (!res?.error) {
        // Extract the ETH price from the response
        setETHPrice(res?.data)
      }
    } catch (error) {
      console.error('Error fetching ETH price:', error.message)
    }
  }

  const getPreSaleData = async () => {
    const res = await dispatch(
      callApiGetMethod('LANDING_PAGE', {}, false, false)
    )
    if (res?.status) {
      setRaised(res?.data)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getHealixPrice()
    }, 3000)
    getBNBValueInUSD()
    getETHValue()
    getPreSaleData()
  }, [])

  useEffect(() => {
    if (isConnected) {
      getUserNativeBalance()
      if (NETWORKS.chainId !== chainId) {
        switchNetwork(NETWORKS.chainId)
      }
    }
  }, [isConnected])

  const progressBar = (
    (raised?.usdtRaised / getTokenAndValue(healixPrice)?.usdRaised) *
    100
  )?.toFixed(0)
  return (
    <div className="container">
      <hr />
      <div className="section-2">
        {/* <ul
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          data-aos-duration="1500"
        >
          <li>Heal</li>
          <li>Helix</li>
          <li>HealthX</li>
        </ul> */}
        <div className="parent-container">
          <div
            className="first-div"
            data-aos="fade-right"
            data-aos-delay="100"
            data-aos-duration="1500">
            <h2>Join the wellness ecosystem with our $HLX token presale!</h2>
            <p>
              Healix is the AI-integrated, health and wellness ecosystem which
              allows you to invest and earn right away. Simplify your journey to
              optimal health and well-being with HLX, the key to unlocking a
              world of wellness possibilities.
            </p>
          </div>
          <div
            className="second-div"
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500">
            <img src={setting} alt="setting" className="setting rotating" />
            <div className="profile">
              <img src={profile} alt="profile" />
              <div className="user-deatils">
                <h6>{addresFormate(userAddress)}</h6>
                <p>Welcome back</p>
              </div>
            </div>
            <div className="deatils">
              <h1>Healix Presale</h1>
              <p>{`Stage ${getTokenAndValue(healixPrice)?.stage}`}</p>
            </div>
            <div className="raised">
              <h3>USD Raised:</h3>
              <h4>
                {raised?.usdtRaised
                  ? Number(raised?.usdtRaised.toFixed(0))?.toLocaleString()
                  : '---'}
              </h4>
              {/* <h5>---</h5> */}
              {/* <h4>$2,56,8840</h4> */}

              <h5>{`/$${Number(
                getTokenAndValue(healixPrice)?.usdRaised
              )?.toLocaleString()}`}</h5>
            </div>
            <div className="raised">
              <h3>Tokens Sold:</h3>
              <h4>
                {raised.totalHLX
                  ? Number(raised.totalHLX.toFixed(0))?.toLocaleString()
                  : '---'}
              </h4>
              {/* <h5>---</h5> */}
              {/* {/* <h4>360,568,840</h4> */}

              <h5>{`/${Number(
                getTokenAndValue(healixPrice)?.availToken
              )?.toLocaleString()}`}</h5>
            </div>
            <div className="raised">
              <h4 style={{ marginLeft: '0px' }}>
                1 HLX = ${healixPrice ? healixPrice : '---'}
              </h4>
            </div>
            {!isNaN(progressBar) && (
              <div className="raised">
                <div className="progress gradient-bk">
                  <div
                    className="incomplete"
                    style={{ width: `${100 - progressBar}%` }}></div>
                  <span>{`${progressBar}%`}</span>
                </div>
              </div>
            )}

            <div className="deatils button_style">
              {paymentMethods.map(item => (
                <button
                  key={item.value}
                  type={'button'}
                  onClick={() => handleButtonClick(item)}
                  style={{
                    background:
                      item.value === activePaymentMethod?.value
                        ? '#6f7677'
                        : '#31393a'
                  }}>
                  <img src={item.icon} alt={item.value} />
                </button>
              ))}
            </div>
            <form>
              <div className="form_style">
                <input
                  placeholder={`Enter ${activePaymentMethod?.value} amount`}
                  required
                  name="amount"
                  id="amount"
                  type="number"
                  value={inputValue}
                  onChange={e => {
                    const inputValue = e.target.value

                    let sanitizedValue = inputValue.replace(/[^0-9.]/g, '')
                    const decimalIndex = sanitizedValue.indexOf('.')

                    if (decimalIndex !== -1) {
                      const beforeDecimal = sanitizedValue.substring(
                        0,
                        decimalIndex
                      )
                      const afterDecimal = sanitizedValue.substring(
                        decimalIndex + 1,
                        decimalIndex + 7
                      )
                      sanitizedValue =
                        beforeDecimal.slice(0, 30) + '.' + afterDecimal
                    } else {
                      sanitizedValue = sanitizedValue.slice(0, 18)
                    }
                    handleInputChange(sanitizedValue)
                  }}
                  // onChange={handleInputChange}
                  onKeyPress={handleNumberInputes}
                />
                <img src={activePaymentMethod?.icon} alt="icon" />
                {/* <div className="balance">
                  {//userBnbBalance ? `Balance : ${userBnbBalance}` : ' '}
                </div> */}
              </div>

              <div className="form_style">
                <input
                  placeholder="Max $HLX Received"
                  // disabled
                  readOnly
                  value={getConvertedInputValue(inputValue)}
                />
                {/* <input
                  placeholder="Max $HLX Received"
                  // disabled
                  readOnly
                  value={
                    inputValue > 0
                      ? activePaymentMethod?.value == 'BNB'
                        ? ((inputValue * bnbPrice) / healixPrice)?.toFixed(4)
                        : activePaymentMethod?.value == 'ETH'
                        ? ((inputValue * ethPrice) / healixPrice)?.toFixed(4)
                        : (inputValue / healixPrice)?.toFixed(4)
                      : 0
                  }
                /> */}
                <img
                  src={icon4}
                  alt="icon"
                  style={
                    activePaymentMethod.value === 'card'
                      ? { background: '#6f7677 ' }
                      : {}
                  }
                />
              </div>

              {!userAddress ? (
                <button
                  onClick={e => {
                    e.preventDefault()
                    open()
                  }}>
                  Connect Wallet
                </button>
              ) : (
                <button
                  style={{ background: '#31393a' }}
                  onClick={e => {
                    e.preventDefault()
                    buyHealix()
                  }}
                  disabled={inputValue <= 0 ? true : false}>
                  Buy with {activePaymentMethod.value}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
