import React, { useRef } from "react";
import "./platform.css";
import logowhite from "../../../assest/logowhite.png";
import phone from "../../../assest/phone.png";
import close from "../../../assest/close-up-dna-strand 1.png";
import { useScroll, m, useTransform } from "framer-motion";

export default function Platform() {

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end center"],
  })
  const clipPath = useTransform(scrollYProgress, [0, 1], ["polygon(0 0, 0% 0, 0% 100%, 0% 100%)", "polygon(0 0, 100% 0, 100% 100%, 0% 100%)"])

  return (
    <div className="healix" >
      <img src={close} alt="close" className="close" />
      <div className="container">
        <div className="flex buy-hlx" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1500">
          <img src={logowhite} alt="logo" /> <h1 ref={ref}>
            The Healix Platform
            <m.div
              style={{ clipPath }}
            >
              The Healix Platform
            </m.div>
          </h1>
        </div>
        <img src={phone} alt="phone" className="phone" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1500" />
      </div>

    </div>
  )
}
