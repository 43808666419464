import Bnbicon from '../assest/icon.png'
import Ethicon from '../assest/eth-icon.png'

export const zeroAddress = '0x0000000000000000000000000000000000000000'
export const projectId = 'f71492b62c17fee4f9bd300208c408ad'
export const API_HOST =
  process.env.REACT_APP_ENVIRONMENT_BACKEND_URL ||
  'https://events.healixprotocol.com/'

// for stage
// export const envType = process.env.REACT_APP_ENV_TYPE || "stage";
// export const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS || "0xF5E7155c7095d0140B80168b494f2efd6eBB74C1";
// export const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS || "0xC6dFaAdFaeA6EeF87ccdDbb5CB8aA30fe2Ba4200";

// for production
export const envType = process.env.REACT_APP_ENV_TYPE || 'production'
export const contractAddress =
  process.env.REACT_APP_CONTRACT_ADDRESS ||
  '0xCfdfa2Df2AA5500546b37c44A719e2ab25Bf9B96'
export const tokenAddress =
  process.env.REACT_APP_TOKEN_ADDRESS ||
  '0x55d398326f99059fF775485246999027B3197955'

export const ethContractAddress =
  process.env.REACT_APP_ETH_CONTRACT_ADDRESS ||
  '0x4d8e8a87F31005F77532a1A3BAE88A09346a928a'
export const ethTokenAddress =
  process.env.REACT_APP_ETH_TOKEN_ADDRESS ||
  '0xdac17f958d2ee523a2206206994597c13d831ec7'

export const metadata = {
  name: 'HEALIX',
  description: 'Healix WalletConnect',
  url: 'https://healixprotocol.com/',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}
export const NETWORKS =
  envType !== 'production'
    ? {
        name: 'Binance',
        currency: 'tBNB',
        chainId: 97, //testnet chainid
        label: 'Binance',
        chainIdHex: '0x61',
        symbol: 'BSC',
        icon: Bnbicon,
        decimals: 18,
        explorerUrl: 'https://testnet.bscscan.com',
        rpcUrl: 'https://bsc-testnet-rpc.publicnode.com/'
      }
    : {
        name: 'Binance',
        currency: 'BSC',
        chainId: 56, //mainnet chain id
        label: 'BSC',
        chainIdHex: '0x38',
        symbol: 'BSC',
        icon: Bnbicon,
        decimals: 18,
        explorerUrl: 'https://bscscan.com',
        rpcUrl: 'https://bsc-dataseed2.defibit.io'
      }

export const ETH_NETWORKS =
  envType !== 'production'
    ? {
        name: 'Sepolia',
        currency: 'SepoliaETH',
        chainId: 11155111, //testnet chainid
        label: 'Binance',
        chainIdHex: '0xAA36A7',
        symbol: 'SepoliaETH',
        icon: Ethicon,
        decimals: 18,
        explorerUrl: 'https://testnet.bscscan.com',
        rpcUrl: 'https://ethereum-sepolia-rpc.publicnode.com'
      }
    : {
        name: 'Ethereum',
        currency: 'ETH',
        chainId: 1, //mainnet chain id
        label: 'ETH',
        chainIdHex: '0x1',
        symbol: 'BSC',
        icon: Ethicon,
        decimals: 18,
        explorerUrl: 'https://bscscan.com',
        rpcUrl: 'https://ethereum-rpc.publicnode.com'
      }

export const RESPONSES = {
  SUCCESS: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NOCONTENT: 204,
  BADREQUEST: 400,
  UN_AUTHORIZED: 401,
  INVALID_REQ: 422,
  FORBIDDEN: 403,
  NOTFOUND: 404,
  TIMEOUT: 408,
  TOOMANYREQ: 429,
  INTERNALSERVER: 500,
  BADGATEWAYS: 502,
  SERVICEUNAVILABLE: 503,
  GATEWAYTIMEOUT: 504
}

export const handleNumberInputes = e => {
  // Check if the pressed key is 'e' or '-'
  if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
    e.preventDefault()
  }
}

export const getError = error => {
  let errorMsg = error && error.message ? error.message : 'Something went wrong'
  if (errorMsg?.includes('user rejected')) {
    return 'user rejected transaction'
  }

  if (errorMsg.includes('Please call connect')) {
    // disconnectWallet();
    return 'Please call connect'
  }

  if (errorMsg.includes('DIRECT_ETH_TRANSFER_NOT_SUPPORTED')) {
    return 'DIRECT ETH TRANSFER NOT SUPPORTED'
  }
  if (errorMsg?.includes('insufficient funds')) {
    return 'insufficient funds'
  }

  if (errorMsg.indexOf('Internal JSON-RPC error') > -1) {
    let msg = errorMsg.replace('Internal JSON-RPC error.', '')
    msg = JSON.parse(msg)
    msg.message =
      msg.message === 'header not found'
        ? 'Binance server is not responding please try again'
        : msg.message.indexOf('err:') > -1 ||
          msg.message.indexOf('execution reverted:') > -1
        ? msg.message.split(':')[msg.message.split(':').length - 1]
        : msg.message
    let convertMsg = msg?.message.replace(/['‘’"“”]/g, '')
    if (convertMsg == ' from address balance is low') {
      return 'Due to a lack of reward balance in the pool, you cannot stake/harvest.'
    }
    return msg.message
  } else if (errorMsg.indexOf('INVALID_ARGUMENT') > -1) {
    return errorMsg.split('(')[0]
  } else if (errorMsg.indexOf('MetaMask Tx Signature') > -1) {
    let msg = errorMsg.replace('MetaMask Tx Signature:', '')
    return msg
  } else if (errorMsg.includes('INVALID_CHAIN')) {
    return 'INVALID CHAIN'
  } else if (errorMsg.includes('UNSUPPORTED_TOKEN')) {
    return 'UNSUPPORTED TOKEN'
  } else if (errorMsg.includes('INVALID_VALIDATOR')) {
    return 'INVALID VALIDATOR'
  } else if (errorMsg.indexOf('execution reverted:') > -1) {
    let msg = errorMsg.split(':')
    msg = msg[2].split('{')

    msg = msg[0].includes("from' address balance is low")
      ? 'Due to a lack of reward balance in the pool, you cannot stake/harvest.'
      : msg[0].split('/n')[0]
    return msg
  } else if (errorMsg.includes('ONLY_ONE_TOKEN_SHOULD_BE_ON_THIS_CHAIN')) {
    return 'ONLY ONE TOKEN SHOULD BE ON THIS CHAIN'
  } else {
    return errorMsg || 'Something went wrong'
  }
}

export const handleWhitePaper = () => {
  window.open('https://healix-protocol.gitbook.io/healix-whitepaper/', '_blank')
}

export const openSocialMedia = platform => {
  let url

  switch (platform.toLowerCase()) {
    case 'instagram':
      url = 'https://www.instagram.com/healixprotocol?igsh=azZieDlteWFmYTF0'
      break
    case 'x':
      url = 'https://x.com/healixprotocol?s=21&t=WQhFDvd-ChY9CtQvop-K3w'
      break
    case 'telegram':
      url = 'https://t.me/healixprotocol'
      break
    case 'medium':
      url = 'https://medium.com/@healixprotocol'
      break
    default:
      console.error('Unsupported social media platform')
      return
  }
  window.open(url, '_blank')
}
export const openSocialMediaLink = (platform, link) => {
  let url

  switch (platform.toLowerCase()) {
    case 'instagram':
      url = 'https://www.instagram.com/'
      break
    case 'linked':
      url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        link
      )}`
      break
    case 'fb':
      url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        link
      )}`
      break
    case 'x':
      url = `https://x.com/intent/tweet?text=${encodeURIComponent(link)}`
      break
    case 'telegram':
      url = `https://t.me/share/url?url=${encodeURIComponent(link)}`
      break
    default:
      console.error('Unsupported social media platform')
      return
  }
  window.open(url, '_blank')
}

export const APIURL = {
  USER_PORTFOLIO: `user/userPortfolio`,
  USER_PROFILE: `/user/userProfile`,
  SING_UP: `auth/signup`,
  VALIDATE_USER: `auth/validate-user`,
  BNB_PRICE: `user/bnbPrice`,
  ETH_PRICE: `user/ethPrice`,
  LANDING_PAGE: 'user/landingPage',
  REFERRAL: `auth/generateLink`,
  REFERRED_WALLET: `auth/connectWallet`,
  PURCHASE: `/auth/purchase`,
  REFERRAL_BONUS: `/auth/referral/bonus`
}

export const addresFormate = data => {
  return `${data?.substring(0, 4)}...${data?.substring(
    data.length - 4,
    data.length
  )}`
}
export function formatTimestamp(timestamp) {
  var date = new Date(timestamp * 1000)
  var day = date.getDate()
  var month = date.getMonth() + 1 // JavaScript months are zero-indexed
  var year = date.getFullYear()
  var formattedDate = day + '/' + month + '/' + year

  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  var formattedTime = hours + ':' + minutes + ' ' + ampm

  return {
    date: formattedDate,
    time: formattedTime
  }
}
const HlxData = [
  { stage: 1, hlxValue: 0.03, availToken: 10000000, usdRaised: 300000 },
  { stage: 2, hlxValue: 0.035, availToken: 20000000, usdRaised: 700000 },
  { stage: 3, hlxValue: 0.04, availToken: 20000000, usdRaised: 800000 },
  { stage: 4, hlxValue: 0.045, availToken: 25000000, usdRaised: 1125000 },
  { stage: 5, hlxValue: 0.05, availToken: 25000000, usdRaised: 1250000 },
  { stage: 6, hlxValue: 0.055, availToken: 25000000, usdRaised: 1375000 },
  { stage: 7, hlxValue: 0.06, availToken: 25000000, usdRaised: 1500000 },
  { stage: 8, hlxValue: 0.07, availToken: 25000000, usdRaised: 1750000 },
  { stage: 9, hlxValue: 0.08, availToken: 25000000, usdRaised: 2000000 },
  { stage: 10, hlxValue: 0.09, availToken: 25000000, usdRaised: 2250000 },
  { stage: 11, hlxValue: 0.1, availToken: 25000000, usdRaised: 2500000 }
]

export function getTokenAndValue(searchParam) {
  for (let i = 0; i < HlxData.length; i++) {
    if (
      HlxData[i].stage === searchParam ||
      HlxData[i].hlxValue === searchParam
    ) {
      return {
        availToken: HlxData[i].availToken,
        usdRaised: HlxData[i].usdRaised,
        stage: HlxData[i].stage
      }
    }
  }
  return {
    availToken: HlxData[0].availToken,
    usdRaised: HlxData[0].usdRaised,
    stage: HlxData[0].stage
  }
}
