import './banner.css'

import Button from '../../assest/Button.png'
import HeroHeader from './hero-header'
import React from 'react'
import { handleWhitePaper } from '../../Services/comon.service'
import hero from '../../assest/hero.png'
import icons from '../../assest/icons.png'
import { m } from 'framer-motion'
import { useLocation } from 'react-router'

export default function Banner() {
  const location = useLocation()
  const pathname = location.pathname
  console.log('pathname', pathname)
  return (
    <>
      <HeroHeader />
      <div className="container">
        <div className="hero">
          <img src={hero} alt="logo" />
          <div className="hero-box">
            <img src={icons} alt="logo" className="adults" />
            <h1>
              <div>
                <m.div
                  initial={{
                    y: 100,
                    opacity: 0
                  }}
                  whileInView={{
                    y: 0,
                    opacity: 1
                  }}
                  transition={{
                    delay: 0.1
                  }}>
                  Multiply Health
                </m.div>
              </div>
              <div>
                <m.div
                  initial={{
                    y: 100,
                    opacity: 0
                  }}
                  whileInView={{
                    y: 0,
                    opacity: 1
                  }}
                  transition={{
                    delay: 0.2
                  }}>
                  Create Wealth
                </m.div>
              </div>
            </h1>
            <img src={Button} alt="logo" className="right " />
            <div className="button-style">
              <button onClick={() => handleWhitePaper()}>Whitepaper</button>
              {/* <button onClick={() => handleWhitePaper()}>Whitepaper</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
