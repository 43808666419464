import './ambassador.css'

import React, { useEffect, useRef, useState } from 'react'
import { m, useScroll, useTransform } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'

import CopyToClipboard from 'react-copy-to-clipboard'
import HlxAmb from '../../../assest/HlxAmb.png'
import building from '../../../assest/bg-component1.png'
import { callApiPostMethod } from '../../../redux/Actions/api.action'
import coin from '../../../assest/coin-amb.png'
import fb_logo from '../../../assest/fb_logo2.png'
import insta_logo from '../../../assest/insta_logo.png'
import linkdIn_logo from '../../../assest/linkdIn_logo.png'
import logo from '../../../assest/logo.png'
import { openSocialMediaLink } from '../../../Services/comon.service'
import { setReferral } from '../../../redux/Wallet/wallet'
import telegram_logo from '../../../assest/telegram_logo.png'
import { useUserWalletConnect } from '../../../Services/useWalletConnect'
import x_logo from '../../../assest/x_logo.png'

export default function HlxAmbassador() {
  const ref = useRef(null)
  const dispatch = useDispatch()
  const { open, isConnected } = useUserWalletConnect()
  const walletAddress = useSelector(state => state?.login?.walletAddress)
  const referral = useSelector(state => state?.login?.referralLink)
  // const referralID = useSelector(state => state?.referral?.referralCode)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end center']
  })
  const clipPath = useTransform(
    scrollYProgress,
    [0, 1],
    [
      'polygon(0 0, 0% 0, 0% 100%, 0% 100%)',
      'polygon(0 0, 100% 0, 100% 100%, 0% 100%)'
    ]
  )
  const hlxRef = useRef(null)
  const { scrollYProgress: hlxProgress } = useScroll({
    target: hlxRef,
    offset: ['start end', 'end start']
  })
  const section = useRef(null)
  const { scrollYProgress: sectionProgress } = useScroll({
    target: section,
    offset: ['start end', 'end start']
  })
  const building1Y = useTransform(sectionProgress, [0, 1], ['100%', '-100%'])
  const [isCopied, setIsCopied] = useState(false)
  const [referralLink, setReferralLink] = useState('')

  const getUserReferralLink = async () => {
    try {
      if (walletAddress) {
        const res = await dispatch(
          callApiPostMethod(
            'REFERRAL',
            { walletAddress: walletAddress },
            '',
            false
          )
        )
        dispatch(setReferral(res.data.data))
        setReferralLink(res.data.data)
      }
    } catch (error) {
      console.log(error, 'error')
    }
  }

  useEffect(() => {
    if (walletAddress && !referral) getUserReferralLink()
  }, [walletAddress, referral])

  // const setReferredWallet = async () => {
  //   try {
  //     if (walletAddress) {
  //       const res = await dispatch(
  //         callApiPostMethod(
  //           'REFERRED_WALLET',
  //           { walletAddress: walletAddress, referralCode: referralID },
  //           '',
  //           false
  //         )
  //       )
  //       dispatch(resetReferral())
  //     }
  //   } catch (error) {
  //     console.log(error, 'error')
  //   }
  // }
  // useEffect(() => {
  //   setReferredWallet()
  // }, [walletAddress])

  const handleShare = media => {
    if (referralLink) openSocialMediaLink(media, referralLink)
  }

  return (
    <div ref={section} className="ambassador">
      <m.img
        style={{
          y: building1Y
        }}
        src={building}
        alt="logo"
        className="building"
      />
      {/* <m.img
        style={{
          y: building1Y
        }}
        src={building2}
        alt="logo"
        className="building1"
      /> */}
      <m.img
        style={{
          y: building1Y
        }}
        src={coin}
        alt="logo"
        className="coin tw-hidden md:tw-block"
      />
      {/* <img src={roadmap} alt="roadmap" className="roadmap-bg" /> */}
      <div className="container tw-z-10">
        <div
          className="flex buy-hlx"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          data-aos-duration="1500">
          <img src={logo} alt="logo" />

          <h1 ref={ref}>
            HLX Ambassador Program
            <m.div style={{ clipPath }}>HLX Ambassador Program</m.div>
          </h1>
        </div>
        <div ref={hlxRef} className="HlxAmb">
          <m.img
            style={{
              y: useTransform(hlxProgress, [0, 1], [30, -30])
            }}
            src={HlxAmb}
            alt="token"
            data-aos="fade-left"
            data-aos-anchor-placement="top-center"
            data-aos-duration="1500"
          />
        </div>
        <div className="tw-bg-[#E3E3E3] tw-rounded-[40px] tw-min-h-60 tw-my-10 md:tw-p-10 tw-p-5 tw-flex md:tw-flex-row tw-flex-col-reverse tw-justify-between tw-items-center md:tw-gap-14">
          <div className="tw-basis-2/5 tw-min-h-20 tw-space-y-4 tw-text-center">
            <h4 className="!tw-text-3xl !tw-font-normal md:tw-px-5 !tw-mb-0">
              Earn more HLX by referring your friends and community
            </h4>
            <div className="tw-h-[1px] tw-bg-white tw-my-8" />
            <p className="tw-opacity-70">
              Share your unique link below and recieve 5% of all transactions
              realized with your link instantly!
            </p>
            <div
              style={{ border: '1px solid #4D4D4D' }}
              className="tw-flex tw-rounded-md tw-overflow-clip">
              <input
                value={referralLink}
                readOnly
                style={{ border: 'none' }}
                className="tw-h-12 tw-w-full tw-p-5"
                placeholder="Connect Wallet for Referral Link"
              />
              <CopyToClipboard
                onCopy={() => setIsCopied(true)}
                text={referralLink}>
                {referralLink ? (
                  <button
                    style={{ border: 'none' }}
                    className="tw-cursor-pointer tw-bg-[#4D4D4D] tw-text-white tw-w-20 tw-h-12 hover:tw-text-white/70">
                    {isCopied ? 'Copied' : 'Copy'}
                  </button>
                ) : (
                  <button
                    disabled
                    style={{ border: 'none' }}
                    className="tw-cursor-pointer tw-bg-[#4D4D4D] tw-text-white tw-w-20 tw-h-12 hover:tw-text-white/70">
                    {isCopied ? 'Copied' : 'Copy'}
                  </button>
                )}
              </CopyToClipboard>
            </div>

            {!walletAddress && (
              <button
                onClick={e => {
                  e.preventDefault()
                  open()
                }}
                style={{ border: 'none' }}
                className="tw-cursor-pointer tw-w-full tw-rounded-full tw-bg-[#4D4D4D] hover:tw-text-white/70 tw-h-12 tw-text-white">
                Connect Wallet
              </button>
            )}

            <div className="tw-flex tw-w-full tw-gap-2 tw-justify-center">
              {/* <div className="tw-h-[1px] tw-bg-white" /> */}
              <p className="tw-text-xs">Share it directly on social media!</p>
              {/* <div className="tw-h-[1px] tw-bg-white" /> */}
            </div>
            <div className="tw-mb-5 tw-flex tw-gap-5 tw-justify-center">
              <div
                onClick={() => {
                  handleShare('x')
                }}
                className="tw-rounded-full tw-bg-[#fff] tw-p-4 tw-w-fit tw-h-fit  tw-cursor-pointer">
                <img src={x_logo} alt="x" className="tw-w-5" />
              </div>
              <div
                onClick={() => {
                  handleShare('linked')
                }}
                className="tw-rounded-full tw-bg-[#fff] tw-p-4 tw-w-fit tw-h-fit  tw-cursor-pointer">
                <img src={linkdIn_logo} alt="li" className="tw-w-5" />
              </div>
              <div
                onClick={() => {
                  handleShare('fb')
                }}
                className="tw-rounded-full tw-bg-[#fff] tw-p-4 tw-w-fit tw-h-fit  tw-cursor-pointer">
                <img src={fb_logo} alt="fb" className="tw-w-5" />
              </div>
              <div
                onClick={() => {
                  handleShare('telegram')
                }}
                className="tw-rounded-full tw-bg-[#fff] tw-p-4 tw-w-fit tw-h-fit  tw-cursor-pointer">
                <img src={telegram_logo} alt="telegram" className="tw-w-5" />
              </div>
              {/* <div
                onClick={() => {
                  handleShare('instagram')
                }}
                className="tw-rounded-full tw-bg-[#fff] tw-p-4 tw-w-fit tw-h-fit  tw-cursor-pointer">
                <img src={insta_logo} alt="twitter" className="tw-w-5" />
              </div> */}
            </div>
          </div>
          <div className="tw-basis-3/5 tw-min-h-20">
            <h1 className="!tw-text-black !tw-font-medium md:tw-leading-[3.5rem]">
              Earn a 5% bonus from all referred users!
            </h1>
            <p className="!tw-text-lg tw-opacity-70">
              Share your unique link with friends, family and your network, to
              receive 5% of all of their transactions in HLX tokens.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
