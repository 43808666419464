import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  referralCode: null
}
export const ReferralSlice = createSlice({
  name: 'Referral',
  initialState,

  reducers: {
    setReferredId: (state, param) => {
      const { payload } = param
      state.referralCode = payload
    },
    resetReferral: state => {
      state.referralCode = null
    }
  }
})

export const { setReferredId, resetReferral } = ReferralSlice.actions
