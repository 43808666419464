import React, { useContext } from 'react'

import About from '../component/home/about/about'
import Accordion from '../component/home/accordion/accordion'
import Banner from '../component/home/banner'
import Buy from '../component/home/buy/buy'
import Covered from '../component/home/covered/covered'
import Crypto from '../component/home/crypto/crypto'
import FeaturedSlider from '../component/home/seen/slider'
import Footer from '../component/home/footer/footer'
import HlxAmbassador from '../component/home/ambassador/ambassador'
import Join from '../component/home/join/join'
import Navigation from './navbar'
import Platform from '../component/home/platform/platform'
import Roadmap from '../component/home/roadmap/roadmap'
import Seen from '../component/home/seen/seen'
import Signup from '../component/home/signup/signup'
import Token from '../component/home/token/token'

export default function Home() {
  return (
    <>
      <div className="home">
        <Navigation />
        <Banner />
        {/* <Signup /> */}
        <About />
        <HlxAmbassador />
        <Covered />
      </div>
      <FeaturedSlider />
      {/* <Seen /> */}
      <Platform />
      <Token />
      <Roadmap />
      <div className="home hiddenMobile">
        {/* <Buy /> */}
        <Crypto />
        <Accordion />
        <Join />
      </div>
      <Footer />
    </>
  )
}
