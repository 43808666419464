import axios from "axios";
// import { disconnectWallet } from "../redux/Actions/user.action";
import store from "../redux/store/store";
import toaster from "../component/Toast";
import { API_HOST, RESPONSES } from "./comon.service";

export const storeInstance = store;
axios.defaults.baseURL = API_HOST;

let failedQueue = [];
const formatUrl = (url, params) => {
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : ``;
  return `${url}${params}`;
};

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

/**AXIOS INTERCEPTOR */
axios.interceptors.request.use(
  (config) => {
    // config.headers['Authorization'] = walletAddress;
    config.headers["Content-Type"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
  },
  (error) => {
    return error;
  }
);

/**HANDLE AXIOS RESPONSE */
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      toaster.error("Server not responding. Please try again later.");
      throw new error(
        error.response || "Server not responding. Please try again later."
      );
    } else {
      return manageErrorConnection(error);
    }

    const originalRequest = error.config;
    failedQueue.push(originalRequest);
    // CommonService.handleJWTExpiry(error)
    if (error.response.status === 403) {
      processQueue(error, null);
    }
  }
);
/**HANDLE AXIOS ERROR */
function manageErrorConnection(err) {
  try {
    if (
      err.response &&
      err.response.status >= 400 &&
      err.response.status <= 500
    ) {
      toaster.error(
        err?.response?.data?.msg ||
          err?.response?.data?.message ||
          "unauthorized user"
      );
      if (err.response.status === 401) {
        // setTimeout(function () {
        //   disconnectWallet();
        // }, 1000);
      }
      return Promise.reject(err);
    } else if (err.code === "ECONNREFUSED") {
      toaster.error("ECONNREFUSED");
      return "nevermind";
    } else {
      toaster.error(err || "something went wrong please try again");
      return Promise.reject(err);
    }
  } catch (err) {
    toaster.error(err || "something went wrong please try again");
  }
}

/**HANDLE AXIOS SUCCESS */
function handleSuccess(res) {
  if (res.status === RESPONSES.SUCCESS || res.status === RESPONSES.CREATED)
    res.data.message && toaster.success(res.data.message || "Success");
  else {
    res?.data?.error &&
      toaster.error(
        res.data?.message ||
          res.data?.error.message ||
          "something went wrong please try again"
      );
  }
}

/**METHOD FOR CALL API */
export const apiCallPost = (url, data, params = {}, showtoaster = false) =>
  new Promise((resolve) => {
    // const ciphertext = CryptoJS.AES.encrypt(
    //   JSON.stringify(data),
    //   "ENTER_YOUR_SECRET_KEY"
    // ).toString();
    axios
      .post(formatUrl(url, params), data)
      .then((res) => {
        showtoaster && handleSuccess(res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err, "err");

        resolve(null);
      });
  });

/**METHOD FOR SEND API */
export const apiCallGet = (url, params = {}, showtoaster = false) =>
  new Promise((resolve) => {
    axios
      .get(formatUrl(url, params))
      .then((res) => {
        showtoaster && handleSuccess(res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err, "err");
        resolve(null);
      });
  });
