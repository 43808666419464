import { useEffect } from 'react'
import {
  useWeb3Modal,
  useDisconnect,
  useWeb3ModalAccount,
  useWeb3ModalProvider
} from '@web3modal/ethers/react'
// import {useWeb3Modal,useDisconnect,useWeb3ModalAccount,useWeb3ModalProvider} from "@web3modal/wagmi/react"
import { useDispatch, useSelector } from 'react-redux'
import { NETWORKS, ETH_NETWORKS } from './comon.service'
import { setWalletAddress } from '../redux/Wallet/wallet'

export const useUserWalletConnect = () => {
  const dispatch = useDispatch()
  const { open } = useWeb3Modal()
  const { disconnect } = useDisconnect()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  let { walletProvider } = useWeb3ModalProvider()
  const walletAddress = useSelector(state => state?.login?.walletAddress)

  const changeDefaultNetwork = async () => {
    if (
      (chainId != NETWORKS.chainId || chainId != ETH_NETWORKS.chainId) &&
      !walletAddress
    ) {
      try {
        const network = NETWORKS

        if (network) {
          await walletProvider?.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: network.chainIdHex }]
          })
        }
      } catch (error) {
        console.error(error)
        return error
      }
    }
  }

  const switchNetwork = async chainId => {
    try {
      const network = NETWORKS
      if (network) {
        await walletProvider?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: network.chainIdHex }]
        })
        return true
      }
    } catch (error) {
      console.error(error)
      // throw error;
    } finally {
      // dispatch(loader(false));
    }
  }

  useEffect(() => {
    if (address) {
      dispatch(setWalletAddress(address))
    }
    if (address === null || address === undefined) {
      const timeoutId = setTimeout(() => {
        if (walletAddress) {
          dispatch(setWalletAddress(address))
        }
      }, 3000)
      return () => clearTimeout(timeoutId)
    }
  }, [address])

  return {
    open,
    disconnect,
    address,
    chainId,
    isConnected,
    walletProvider,
    changeDefaultNetwork,
    switchNetwork
  }
}
